import { useState } from "react";
import { updateEvolutionAPI } from "../../../api/protocol";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";

export const useEvolutions = () => {
    const [isLoading, setIsLoading] = useState(false);

    const updateEvolution = async (protocolId, visitId, patientId, evolutionId, evolution, audit) => {
        try {
            var pepepe = {
                evolution: evolution,
                audit: audit
            }
            setIsLoading(true);
            const evolutionRes = await updateEvolutionAPI(protocolId, visitId, patientId, evolutionId, pepepe);
            setIsLoading(false);

            return evolutionRes;

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    return [isLoading, updateEvolution];
}