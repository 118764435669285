import React, { useState } from "react";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { useProcedures } from "../Hooks/useProcedures";
import { Procedures } from "../../../components/MedicalRecords/Procedures";
import { AuditModal } from "../../../components/AuditTrail/AuditModal";
import { AuditTrail } from "../../Admin/AuditTrail";
import { useTranslation } from "react-i18next";
import { ModalProcedureHistory } from "../Modals/ModalProcedureHistory";

export const ProceduresListContainer = ({ mrn, medicalRecordNumber, entryId, expandedAll }) => {
    const { t } = useTranslation();
    const [drugs, query, totalSize, isLoading, handleTableChange,] = useProcedures(medicalRecordNumber, entryId);

    const [modal, setModal] = useState(null);
    const handleAuditTrail = (entityId) => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditTrail entity="Procedures" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }
    const handleHistory = (_itemId, _mrn, item) => {  
        setModal(<ModalProcedureHistory
            onClose={() => { setModal(null); }}
            title={t("medicalRecords.history.history")}
            medicalRecordNumber={medicalRecordNumber || mrn}
            conceptId={item.name?.conceptId}
        />)
    }

    if (totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.procedures.title")} content="procedures" expandedAll={expandedAll}>
            {modal}
            <Procedures
                withCard={false}
                data={drugs}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onAuditTrail={handleAuditTrail}
                onHistory={handleHistory}
            />
        </ExpandableContent>
    );
}