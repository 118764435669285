import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Audit, Chart, Edit, View } from '../Icons/Icons';
import { dateFormatter } from '../Utils/Formatter';
import { Tooltip } from '../Utils/Tooltips';
import { EntryItemsTable } from './EntryItemsTable';

export const Laboratory = (props) => {
    const { t } = useTranslation();
    var { onEdit, onAuditTrail, onLaboratoryResultHistory, ...rest } = props;

    const columns = [
        { dataField: 'laboratoryDate', text: t('commons.date'), formatter: function (cell) { return dateFormatter(cell, "DD/MM/YY") }, headerClasses: 'header-date' },
        {
            isDummyField: true,
            dataField: 'resultsCount',
            text: "Cantidad Resultados",
            headerClasses: 'header-date',
            formatter: function (_cell, row) {
                return <div>{row.results?.length}</div>
            }
        }
    ];

    const columnsResults = [
        { dataField: 'name.description', text: t('medicalRecords.laboratory.laboratory') },
        {
            dataField: 'result', text: t('medicalRecords.laboratory.result'),
            formatter: function (_cell, row) {
                return <div>{`${row.result} ${row.unit?.title}`}</div>
            }
        },
        {
            dataField: 'actions',
            text: t("commons.actions"),
            isDummyField: true,
            headerClasses: 'header-actions',
            classes: 'row-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={`${Math.random()}-actions-${rowIndex}`}>
                        {
                            onLaboratoryResultHistory &&
                            <Tooltip tooltip={t("medicalRecords.history.history")} id={"tooltiphistory-" + row.id}>
                                <button className="btn-table-actions" onClick={() => onLaboratoryResultHistory(row.id)}>
                                    <Chart />
                                </button>
                            </Tooltip>
                        }
                        {
                            row.entryId != props.entryId && props.query.medicalRecordNumber !== 0 &&
                            <Tooltip tooltip={t("medicalRecords.viewVisit")} id={"tooltipview-" + row.id}>
                                <NavLink to={`/admin/medicalRecords/${props.query.medicalRecordNumber}/entry/${row.entryId}`} className="nav-link" activeClassName="active">
                                    <View />
                                </NavLink>
                            </Tooltip>
                        }
                        {
                            row.hasAudit && onAuditTrail &&
                            <Tooltip tooltip={t("auditTrail.view")} id={"tooltipaudit-" + row.id}>
                                <button className="btn-table-actions" onClick={() => onAuditTrail(row.originalId ?? row.id)}>
                                    <Audit />
                                </button>
                            </Tooltip>
                        }
                        {
                            onEdit &&
                            <Tooltip tooltip="Editar" id={"tooltipedit-" + row.id}>
                                <button className="btn-table-actions" onClick={() => onEdit(row.id)}>
                                    <Edit />
                                </button>
                            </Tooltip>
                        }
                    </div>
                )
            }
        }
    ];

    const expandedContent = (row, _rowIndex) => {
        return (
            <BootstrapTable
                bordered={false}
                classes="table-spaced table-spaced-small"
                wrapperClasses="table-responsive"
                striped
                hover
                keyField='id'
                data={row.results}
                columns={columnsResults}
                noDataIndication={() => <>{t("commons.noData")}n</>}
                pagination={paginationFactory({
                    page: 1,
                    sizePerPage: 6,
                    totalSize: row.results.length,
                    hideSizePerPage: true,
                    hidePageListOnlyOnePage: true
                })}
            />
        );
    };

    return (<EntryItemsTable
        {...rest}
        columns={columns}
        expandContent={expandedContent}
    />);
}
