import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from 'react-i18next';
import { AuditModal, CreateAudit, CreateAuditModal } from "../../../components/AuditTrail/AuditModal";
import { IconSVG } from '../../../components/Icons/Icons';
import { OrdersForm } from "../../../components/MedicalRecords/Forms/OrdersForm";
import { Orders } from '../../../components/MedicalRecords/Orders';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { getMedicalRecordsSchema } from '../../../components/Validations/FormValidationSchema';
import { AuditTrail } from "../../Admin/AuditTrail";
import { useDrug } from "../Hooks/useDrugs";
import { useOrder, useOrders } from '../Hooks/useOrders';
import { ModalData } from '../Modals/ModalData';

export const OrdersContainer = (props) => {
    const [content, setContent] = useState(null);
    const { entryStatus, entryId, medicalRecordNumber, showAddButton, expandedAll } = props;
    const [orders, query, totalSize, isLoading, handleTableChange, setReload] = useOrders(medicalRecordNumber, null);
    const [, , , , , print] = useOrder();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.contentToReload?.contentName === "orders")
            setReload(true);
    }, [props.contentToReload]);

    const handleAdd = () => {
        setContent(
            <ModalData
                isShowing={true}
                hide={handleClose}
                title={t(`medicalRecords.orders.title`)}
            >
                <OrdersCreateContainer {...props} hide={handleClose} onSubmit={handleSubmit} />
            </ModalData>

        );
    }

    const handleEdit = (id) => {
        setContent(
            <ModalData
                isShowing={true}
                hide={handleClose}
                title={t(`medicalRecords.orders.title`)}
            >
                <OrderEditContainer
                    medicalRecordNumber={medicalRecordNumber}
                    entryId={entryId}
                    entryStatus={entryStatus}
                    id={id}
                    patient={props.patient}
                    onSubmit={handleSubmit}
                    onCancel={handleClose}
                />
            </ModalData>);
    }

    const handleCopy = async (_entryId2, id) => {
        setContent(
            <ModalData
                isShowing={true}
                hide={handleClose}
                title={t(`medicalRecords.orders.title`)}
            >
                <OrderCopyContainer
                    medicalRecordNumber={medicalRecordNumber}
                    entryId={entryId}
                    entryStatus={entryStatus}
                    id={id}
                    patient={props.patient}
                    onSubmit={handleSubmit}
                    onCancel={handleClose}
                />
            </ModalData>);
    }

    const handleSubmit = () => {
        setContent(null);
        setReload(true);
        if (props.onSubmit)
            props.onSubmit("orders")
    }

    const handleClose = () => {
        setContent(null);
    }

    const handleAuditTrail = (entityId) => {
        setContent(
            <AuditModal
                onClose={() => { setContent(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditTrail entity="OrdersItems" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    const handlePrint = async (entryId2, id) => {
        try {
            var response = await print(medicalRecordNumber, entryId2, id, null);

            const blob = response.data;
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        }
        catch (error) {
            HandleApiError(error);
            console.log(error);
        };
    }

    return (
        <>
            {content}
            {
                showAddButton &&
                <div style={{ marginTop: '20px' }}>
                    <button key="buttonorder" name="orders" type="button"
                        className="btn btn-primary btn-fill btn-full"
                        onClick={handleAdd}
                        style={{ marginBottom: '5px', textAlign: 'left' }}>
                        <><IconSVG name="orders" fill="#fff" /> {t("commons.add")} {t(`medicalRecords.orders.title`)}</>
                    </button>
                </div>
            }
            <Orders
                withCard={false}
                entryId={entryId}
                data={orders}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onEdit={handleEdit}
                onAuditTrail={handleAuditTrail}
                onPrint={handlePrint}
                onCopy={handleCopy}
            />
        </>
    );
}
OrdersContainer.defaultProps = {
    showAddButton: false
}
OrdersContainer.propTypes = {
    onSubmit: PropTypes.func,
    entryStatus: PropTypes.string,
    entryId: PropTypes.string,
    medicalRecordNumber: PropTypes.string,
    showAddButton: PropTypes.bool,
    expandedAll: PropTypes.bool
};

export const OrdersCreateContainer = (props) => {
    const { entryStatus, entryId, medicalRecordNumber, onSubmit } = props;
    const [, masterData, isLoading] = useDrug(medicalRecordNumber, entryId);
    const [, , , create] = useOrder();
    const [content, setContent] = useState(null);

    const handleSubmit = async (data) => {

        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAudit
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (reason) {
                data.auditReason = reason;
                data.auditComments = comments;
            }
            data.orders.forEach(x => { (!x.reasonId && x.reason && (x.reasonId = x.reason.id)); x.reason = null; }); // Mapeo Reason
            await create(entryId, data);
            setContent(null);
            onSubmit("orders")
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleCancel = () => {
        if (props.hide)
            props.hide()
    }

    if (!masterData || isLoading)
        return null;

    return (
        <>
            {
                content ??
                <OrderContainerFormik
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    data={masterData.data}
                    events={masterData.events}
                    patient={props.patient}
                />
            }
        </>

    );
}

const OrderCopyContainer = (props) => {
    const { medicalRecordNumber, entryId, entryStatus, id, onSubmit, onCancel } = props;
    const [data, masterData, isLoading, create] = useOrder(medicalRecordNumber, entryId, id);
    const [content, setContent] = useState(null);

    const handleSubmit = async (data) => {

        if (!data || !data.orders || data.orders.length === 0)
            return;

        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (data && data.orders && data.orders.length > 0) {
                if (reason) {
                    data.auditReason = reason;
                    data.auditComments = comments;
                }
                data.orders.forEach(x => { (!x.reasonId && x.reason && (x.reasonId = x.reason.id)); x.reason = null; }); // Mapeo Reason
                await create(entryId, data);
                setContent(null);
                onSubmit("orders");
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    if (!data || !masterData || isLoading)
        return null;

    return (
        <>
            {content}
            <OrderContainerFormik
                onSubmit={handleSubmit}
                onCancel={onCancel}
                data={masterData.data}
                events={masterData.events}
                orders={data}
                patient={props.patient}
            />
        </>
    );
}

const OrderEditContainer = (props) => {
    const { medicalRecordNumber, entryId, entryStatus, id, onSubmit } = props;
    const [data, masterData, isLoading, , update] = useOrder(medicalRecordNumber, entryId, id);
    const [content, setContent] = useState(null);

    const handleSubmit = async (data) => {

        if (!data || !data.orders || data.orders.length === 0)
            return;

        if (entryStatus === "Published" || entryStatus === "AutoPublished" || entryId != data.orders[0].entryId) {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (data && data.orders && data.orders.length > 0) {
                if (reason) {
                    data.auditReason = reason;
                    data.auditComments = comments;
                }
                data.orders.forEach(x => { (!x.reasonId && x.reason && (x.reasonId = x.reason.id)); x.reason = null; }); // Mapeo Reason
                await update(entryId, id, data)
                onSubmit();
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    if (!data || !masterData || isLoading)
        return null;

    return (
        <>
            {content}
            <OrderContainerFormik
                onSubmit={handleSubmit}
                onCancel={props.onCancel}
                data={masterData.data}
                events={masterData.events}
                orders={data}
                patient={props.patient}
            />
        </>
    );
}

const OrderContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => ({
        orderDate: props.orders?.orderDate ?? new Date(),
        medicalInsurance: props.orders?.medicalInsurance ?? props.patient?.medicalInsurance,
        medicalInsurancePlan: props.orders?.medicalInsurancePlan ?? props.patient?.medicalInsurancePlan,
        medicalInsuranceNumber: props.orders?.medicalInsuranceNumber ?? props.patient?.medicalInsuranceNumber,
        orders: props.orders?.orders ?? []
    }),

    validationSchema: getMedicalRecordsSchema().orders,

    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },

    displayName: 'OrdersForm',

})(OrdersForm));

OrderContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    data: PropTypes.object
};