import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from 'react-i18next';
import { putAnthropometric } from "../../../api/medicalRecord";
import { AuditModal, CreateAudit, CreateAuditModal } from "../../../components/AuditTrail/AuditModal";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { Anthropometrics } from "../../../components/MedicalRecords/Anthropometrics";
import { AnthropometricsForm } from "../../../components/MedicalRecords/Forms/AnthropometricsForm";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { getMedicalRecordsSchema } from "../../../components/Validations/FormValidationSchema";
import { AuditTrail } from "../../Admin/AuditTrail";
import { useAnthropometric, useAnthropometrics } from "../Hooks/useAnthropometrics";
import { ModalAnthropometricsHistory } from '../Modals/ModalAnthropometricsHistory';


export const AnthropometricsContainer = (props) => {
    const [content, setContent] = useState(null);
    const { medicalRecordNumber, mrn, entryStatus, entryId, expandedAll } = props;
    const [anthropometrics, query, totalSize, isLoading, handleTableChange, setReload] = useAnthropometrics(null, entryId);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.contentToReload?.contentName === "anthropometrics")
            setReload(true);
    }, [props.contentToReload]);

    const handleEdit = (id) => {
        setContent(<AnthropometricEditContainer
            entryId={entryId}
            id={id}
            onSubmit={handleSubmit}
            onCancel={handleClose}
        />);
    }

    const handleSubmit = async (data) => {

        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (reason) {
                data.auditReason = reason;
                data.auditComments = comments;
            }
            await putAnthropometric(entryId, data.id, data);
            setContent(null);
            setReload(true);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleClose = () => {
        setContent(null);
    }

    const handleAuditTrail = (entityId) => {
        setContent(
            <AuditModal
                onClose={() => { setContent(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditTrail entity="Anthropometrics" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    const handleHistory = () => {
        setContent(<ModalAnthropometricsHistory
            onClose={() => { setContent(null); }}
            title={t("medicalRecords.history.history")}
            medicalRecordNumber={medicalRecordNumber || mrn}
        />)
    }

    if (totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.anthropometrics.title")} expandedAll={expandedAll} content="anthropometrics">
            {content}
            <Anthropometrics
                withCard={false}
                entryId={entryId}
                data={anthropometrics}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onEdit={handleEdit}
                onAuditTrail={handleAuditTrail}
                onHistory={handleHistory}
            />
        </ExpandableContent>
    );
}

export const AnthropometricCreateContainer = (props) => {
    const { entryStatus, entryId, onSubmit } = props;
    const [, , create,] = useAnthropometric(entryId);
    const [content, setContent] = useState(null);

    const handleSubmit = async (data) => {

        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAudit
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (reason) {
                data.auditReason = reason;
                data.auditComments = comments;
            }
            await create(entryId, data);
            setContent(null);
            onSubmit("anthropometrics");
        }
        catch (error) {
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleCancel = () => {
        props.hide()
    }

    return (
        <>
            {
                content ??
                <AnthropometricContainerFormik
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    anthropometric={{
                        weight: '',
                        height: ''
                    }}
                />
            }
        </>

    );
}

const AnthropometricEditContainer = (props) => {
    const { entryId, id, onSubmit } = props;
    const [data, isLoading] = useAnthropometric(entryId, id);

    if (!data || isLoading)
        return null;

    return (
        <AnthropometricContainerFormik
            onSubmit={onSubmit}
            onCancel={props.onCancel}
            anthropometric={data}
        />
    );
}

const AnthropometricContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => (props.anthropometric),

    validationSchema: getMedicalRecordsSchema().anthropometrics,

    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },

    displayName: 'AnthropometricsForm',

})(AnthropometricsForm));

AnthropometricContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    anthropometric: PropTypes.object.isRequired,
};