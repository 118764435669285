import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Card } from "../../../components/Cards/Card";
import { AdverseEventsListContainer } from "./AdverseEventsListContainer";
import { AnthropometricsListContainer } from "./AnthropometricsListContainer";
import { AttachmentsListContainer } from "./AttachmentsListContainer";
import { DrugsListContainer } from "./DrugsListContainer";
import EntriesContainer from "./EntriesContainer";
import { FamilyHistoryListContainer } from "./FamilyHistoryListContainer";
import { LaboratoryListContainer } from "./LaboratoryListContainer";
import { PersonalHabitsListContainer } from "./PersonalHabitsListContainer";
import { PersonalHistoryListContainer } from "./PersonalHistoryListContainer";
import { ProceduresListContainer } from "./ProceduresListContainer";
import { PrescriptionsListContainer } from "./PrescriptionsListContainer";
import { VitalsListContainer } from "./VitalsListContainer";
import { OrdersListContainer } from "./OrdersListContainer";
import { ConditionsListContainer } from "./ConditionsListContainer";
import { useTranslation } from "react-i18next";

export const EntryItemsList = (props) => {
    const [expandedAll, setExpandedAll] = useState(true);
    const { t } = useTranslation();

    return (
        <Card
            header={
                <>
                    <button className="btn btn-right btn-primary" onClick={() => setExpandedAll(!expandedAll)}>
                        {
                            expandedAll ? <>{t("commons.colapseAll")}</> : <>{t("commons.expandAll")}</>
                        }
                    </button>
                    <div className="clearfix"></div>
                </>
            }

            content={
                <>
                    {
                        props.protocol ?
                            <>
                                <AdverseEventsListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                                <PersonalHistoryListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                            </> :
                            <ConditionsListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    }
                    <DrugsListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    <ProceduresListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    <LaboratoryListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    <VitalsListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    <AnthropometricsListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    <PersonalHabitsListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    <FamilyHistoryListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    <AttachmentsListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                </>
            }
        />
    );
}

export const EntryItemsListTabs = ({ medicalRecordNumber, inProtocol }) => {
    const { t } = useTranslation();
    return (
        <Tabs defaultActiveKey="entries" id="medical-record-resume" mountOnEnter={true}>
            <Tab eventKey="entries" title={t("medicalRecords.tabs.entries")}>
                <Card
                    tabs
                    noHeader
                    content={<EntriesContainer medicalRecordNumber={medicalRecordNumber} view="scroll-small" />}
                />
            </Tab>
            {
                inProtocol && <Tab eventKey="personalHistory" title={t("medicalRecords.tabs.personalHistory")}>
                    <Card
                        tabs
                        noHeader
                        content={<PersonalHistoryListContainer medicalRecordNumber={medicalRecordNumber} expandedAll />}
                    />
                </Tab>
            }
            {
                inProtocol && <Tab eventKey="events" title={t("medicalRecords.tabs.events")}>
                    <Card
                        tabs
                        noHeader
                        content={<AdverseEventsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll />}
                    />
                </Tab>
            }
            {
                !inProtocol && <Tab eventKey="conditions" title={t("medicalRecords.tabs.conditions")}>
                    <Card
                        tabs
                        noHeader
                        content={<ConditionsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll readOnly actual={false} />}
                    />
                </Tab>
            }
            <Tab eventKey="drugs" title={t("medicalRecords.tabs.drugs")}>
                <Card
                    tabs
                    noHeader
                    content={<DrugsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />}
                />
            </Tab>
            <Tab eventKey="procedures" title={t("medicalRecords.tabs.procedures")}>
                <Card
                    tabs
                    noHeader
                    content={<ProceduresListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />}
                />
            </Tab>
            <Tab eventKey="prescriptions" title={t("medicalRecords.tabs.prescriptions")}>
                <Card
                    tab
                    content={<PrescriptionsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />}
                />
            </Tab>
            <Tab eventKey="orders" title={t("medicalRecords.tabs.orders")}>
                <Card
                    tab
                    content={<OrdersListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />}
                />
            </Tab>
            <Tab eventKey="laboratories" title={t("medicalRecords.tabs.laboratories")}>
                <Card
                    tabs
                    noHeader
                    content={<LaboratoryListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />}
                />
            </Tab>
            <Tab eventKey="vitals" title={t("medicalRecords.tabs.vitals")}>
                <Card
                    tabs
                    noHeader
                    content={<VitalsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />}
                />
            </Tab>
            <Tab eventKey="anthropometrics" title={t("medicalRecords.tabs.anthropometrics")}>
                <Card
                    tabs
                    noHeader
                    content={<AnthropometricsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />}
                />
            </Tab>
        </Tabs>
    );
}