import * as Yup from 'yup';
import { setLocale } from 'yup';

setLocale({
    mixed: {
        required: ({ fieldName }) => ({ key: 'formValidation.field_required', values: { fieldName } })
    },
    string: {
        min: ({ min }) => ({ key: 'formValidation.field_too_short', values: { min } }),
        max: ({ max }) => ({ key: 'formValidation.field_too_big', values: { max } }),
        email: 'formValidation.field_mail_invalid',
        matches: 'formValidation.field_format_invalid',
        required: 'formValidation.field_required'
    },
    number: {
        min: ({ min }) => ({ key: 'formValidation.number_field_too_short', values: { min } }),
        max: ({ max }) => ({ key: 'formValidation.number_field_too_big', values: { max } }),
    },
    date: {
        min: ({ min }) => ({ key: 'formValidation.date_gt', values: { min } }),
        max: ({ max }) => ({ key: 'formValidation.date_lt', values: { max } }),
    },
});

export const getAgendaAppointmentSchema = () => {
    return Yup.object().shape({
        //selectedDate: Yup.date().nullable().required(),
        start: Yup.date().nullable().required(),
        visitType: Yup.object().nullable().required(),
        isFirstTime: Yup.object()
            .when("visitType", (visitType) => {
                if (visitType && (visitType?.code?.toLowerCase() !== "studies" && visitType?.code?.toLowerCase() !== "order"))
                    return Yup.bool().nullable().required();
                else
                    return Yup.bool().nullable();
            }),
    });
}

export const getPatientSchema = () => {

    return Yup.object().shape({
        given: Yup.string().nullable().required().min(2).max(50),
        familyName: Yup.string().nullable().required().min(2).max(50),
        identificationType: Yup.string().nullable().required(),
        identificationNumber: Yup.string().matches(/^[0-9]*$/).nullable().required(),
        birthDate: Yup.date().nullable().required().max(new Date(), { key: 'formValidation.date_lt' }),
        gender: Yup.string().nullable().required(),
        address: Yup.object().shape({
            fullAddress: Yup.string().nullable()//.required(),
        }),
        telecom: Yup.object().shape({
            cellPhone: Yup.string().matches(/^(?=.*[0-9])[- +()0-9]+$/).nullable(),
            phone: Yup.string().matches(/^(?=.*[0-9])[- +()0-9]+$/).nullable(),
            email: Yup.string().nullable().email()
        }),
        relatedContacts: Yup.array()
            .of(
                Yup.object().shape({
                    relationship: Yup.string().required().min(2).max(20),
                    given: Yup.string().required().min(2).max(50),
                    familyName: Yup.string().required().min(2).max(50),
                    phone: Yup.string().required(),
                    email: Yup.string().email().required(),
                    streetName: Yup.string().required(),
                    streetNumber: Yup.string().matches(/^[0-9]*$/).required()
                })
            ),
        medicalInsuranceInfo: Yup.object().shape({
            medicalInsurance: Yup.object().nullable(),
            medicalInsuranceNumber: Yup.string()
                .when(["medicalInsurance"], (medicalInsurance) => {
                    if (medicalInsurance)
                        return Yup.string().nullable().required()
                    return Yup.string().nullable()
                }),
        }),
    });
}

export const getUserSchema = () => {

    return Yup.object().shape({
        given: Yup.string().nullable().required(),
        familyName: Yup.string().nullable().required(),
        userName: Yup.string().matches(/^(?=[a-zA-Z0-9._]{3,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
            {
                message: 'El nombre de usuario no debe contener espacios, no debe contener caracteres especiales y debe tener entre 3 y 20 caracteres. ',
                //excludeEmptyString: true,
            }).nullable().required(),
        workEmail: Yup.string().nullable().email().required(),
        //password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!"#$%&\/()=])[A-Za-z\d@!"#$%&\/()=]{6,}$/,
        //    {
        //        message: 'La contraseña debe contener Mayúsculas, Minúsculas, Números, Simbolos y mínimo 6 caracteres. ',
        //        //excludeEmptyString: true,
        //    }).nullable().required(),
        //confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Las contraseñas deben ser iguales'),
        role: Yup.object().nullable().required(),
    });
}

export const getUserProfileSchema = () => {

    return Yup.object().shape({
        given: Yup.string().nullable().required().min(2).max(50),
        familyName: Yup.string().nullable().required().min(2).max(50),
        identificationType: Yup.string().nullable().required(),
        identificationNumber: Yup.string().nullable().required(),
        birthDate: Yup.date().nullable().required().max(new Date(), { key: 'formValidation.date_lt' }),
        nationality: Yup.string().nullable().required(),
        gender: Yup.string().nullable().required(),
        maritalStatus: Yup.string().nullable().required(),
        address: Yup.object().shape({
            fullAddress: Yup.string().nullable().required(),
        }),
        telecom: Yup.object().shape({
            cellPhone: Yup.string().nullable().required(),
            email: Yup.string().nullable().email().required()
        }),
        password: Yup.string()
            .when("rsaPublicCert", (publicCert) => {
                if (!publicCert)
                    return Yup.string();
                else
                    return Yup.string().nullable().required();
            }),
    });
}

export const getTenantSchema = () => {

    return Yup.object().shape({
        tenantName: Yup.string().nullable().required(),
        tenantDomain: Yup.string().nullable().required(),
        servicePlan: Yup.string().nullable().required(),
        isActive: Yup.boolean().nullable().required(),
    });
}

export const getSettingsSchema = () => {
    //resultItems
    return Yup.object().shape({
        name: Yup.string().nullable().required(),
        resultItems: Yup.array()
            .of(
                Yup.object().shape({
                    name: Yup.object().nullable().required(),
                    unit: Yup.object().nullable().required(),
                })
            )
    });
}

const dateRangeYup = {
    fromDay: Yup.number().nullable(),
    fromMonth: Yup.number().nullable(),
    fromYear: Yup.number().required().nullable(),
    toYear: Yup
        .number()
        .when(["fromYear"], (fromYear) => {
            return Yup.number().min(fromYear, { key: 'formValidation.date_range' }).nullable();
        }),
    toMonth: Yup
        .number()
        .when(["fromYear", "fromMonth", "toYear"], (fromYear, fromMonth, toYear) => {
            if (fromYear == toYear && fromMonth)
                return Yup.number().min(fromMonth, { key: 'formValidation.date_range' });
            return Yup.number().nullable();

        }),
    toDay: Yup
        .number()
        .when(["fromYear", "fromMonth", "fromDay", "toYear", "toMonth"], (fromYear, fromMonth, fromDay, toYear, toMonth) => {
            if (fromYear == toYear && fromMonth == toMonth && fromDay)
                return Yup.number().min(fromDay, { key: 'formValidation.date_range' });
            return Yup.number().nullable();
        }),
}
export const getMedicalRecordsSchema = () => {

    return {
        adverseEvent: Yup.object().shape({
            adverseEvents: Yup.array()
                .of(
                    Yup.object().shape({
                        name: Yup.object().required().nullable().default(null),
                        ...dateRangeYup
                    })
                )
        }),
        drugs: Yup.object().shape({
            drugs: Yup.array()
                .of(
                    Yup.object().shape({
                        name: Yup.object().nullable().required(),
                        routeOfAdministration: Yup.object().nullable().required(),
                        reason: Yup.string().required(),
                        dose: Yup.string().nullable().required(),
                        ...dateRangeYup
                    })
                )
        }),
        drugsEdit: Yup.object().shape({
            drugs: Yup.array()
                .of(
                    Yup.object().shape({
                        name: Yup.object().nullable().required(),
                        routeOfAdministration: Yup.object().nullable().required(),
                        dose: Yup.string().nullable().required(),
                        ...dateRangeYup
                    })
                )
        }),
        prescriptionsEdit: Yup.object().shape({
            prescriptionDate: Yup.string().nullable().required(),
            prescriptions: Yup.array()
                .of(
                    Yup.object().shape({
                        packageCount: Yup.number().nullable().required(),
                        drug: Yup.object().nullable().required(),
                        frequency: Yup.object().nullable().required(),
                        unit: Yup.object().nullable().required(),
                        posology: Yup.string().nullable().required(),
                    })
                )
        }),
        orders: Yup.object().shape({
            orderDate: Yup.string().nullable().required(),
            orders: Yup.array()
                .of(
                    Yup.object().shape({
                        procedure: Yup.object().nullable().required()
                    })
                )
        }),
        procedures: Yup.object().shape({
            procedures: Yup.array()
                .of(
                    Yup.object().shape({
                        name: Yup.string().required(),
                        reason: Yup.string().required(),
                        ...dateRangeYup
                    })
                )
        }),
        proceduresEdit: Yup.object().shape({
            procedures: Yup.array()
                .of(
                    Yup.object().shape({
                        name: Yup.string().required(),
                        ...dateRangeYup
                    })
                )
        }),
        laboratories: Yup.object().shape({
            laboratories: Yup.array()
                .of(
                    Yup.object().shape({
                        name: Yup.object().nullable().required(),
                        result: Yup.string().nullable().required(),
                        unit: Yup.object().nullable().required(),
                        //significance: Yup.object().nullable().required()
                    })
                ),
        }),
        resultsTemplates: Yup.object().shape({
            name: Yup.object().nullable(),
            result: Yup.string(),
            unit: Yup.string()
        }),
        anthropometrics: Yup.object().shape({
            weight: Yup.string().matches(/^[1-9]\d*(\.\d+)?$/,
                {
                    message: 'Ingresar un peso válido en Kg. ',
                }).nullable().required(),
            height: Yup.string().matches(/^\d+$/,
                {
                    message: 'La altura debe estar expresada en Centimetros. ',
                }).nullable().required(),
            waist: Yup.string().matches(/^[1-9]\d*(\.\d+)?$/,
                {
                    message: 'Debe estar expresado con \'.\'',
                }).nullable(),
            sagittal: Yup.string().matches(/^[1-9]\d*(\.\d+)?$/,
                {
                    message: 'Debe estar expresado con \'.\'',
                }).nullable(),
            neck: Yup.string().matches(/^[1-9]\d*(\.\d+)?$/,
                {
                    message: 'Debe estar expresado con \'.\'',
                }).nullable(),
        }),
        vitals: Yup.object().shape({
            bloodPressureHigh: Yup.string().matches(/^[1-9]\d*$/, { message: 'Número entero sin decimal', }).required(),
            bloodPressureLow: Yup.string().matches(/^[1-9]\d*$/, { message: 'Número entero sin decimal', }).required(),
            heartRate: Yup.string().matches(/^[1-9]\d*$/, { message: 'Número entero sin decimal', }).required(),
            temperatureC: Yup.string().matches(/^[1-9]\d*(\.\d+)?$/,
                {
                    message: 'Debe estar expresado con \'.\'',
                }).nullable(),
            oxygenSaturation: Yup.string().matches(/^[1-9]\d*(\.\d+)?$/,
                {
                    message: 'Debe estar expresado con \'.\'',
                }).nullable()
        }),
        familyHistory: Yup.object().shape({
            familyHistories: Yup.array()
                .of(
                    Yup.object().shape({
                        name: Yup.object().nullable().required(),
                        relative: Yup.object().nullable().required(),
                    })
                )
        })
    };
}

export const getProtocolBriefSchema = () => {

    return Yup.object().shape({
        brief: Yup.object().shape({
            laboratory: Yup.object().nullable().required(),
            protocolNumber: Yup.string().trim().matches(/^[A-Za-z0-9\-\_]+$/,
                {
                    message: 'Solo Números, Letras, -, _',
                }).required(),
            centerNumber: Yup.string().trim().matches(/^[A-Za-z0-9\-\_]+$/,
                {
                    message: 'Solo Números, Letras, -, _',
                }).required(),
            title: Yup.string().required(),
            fullTitle: Yup.string().required(),
            phase: Yup.object().nullable().required(),
            indication: Yup.object().nullable().required(),
            //status: Yup.object().nullable().required(),
            specialty: Yup.object().nullable().required(),
            patientsObjective: Yup.number().min(1).nullable().required(),
            principalInvestigator: Yup.object().nullable().required(),
            studyRole: Yup.object().nullable().required(),
            currentUserStudyRole: Yup.object().nullable().required(),
        })
    });
}

export const getProtocolBriefEditSchema = () => {

    return Yup.object().shape({
        brief: Yup.object().shape({
            laboratory: Yup.object().nullable().required(),
            protocolNumber: Yup.string().trim().matches(/^[A-Za-z0-9\-\_]+$/,
                {
                    message: 'Solo Números, Letras, -, _',
                }).required(),
            centerNumber: Yup.string().trim().matches(/^[A-Za-z0-9\-\_]+$/,
                {
                    message: 'Solo Números, Letras, -, _',
                }).required(),
            title: Yup.string().required(),
            fullTitle: Yup.string().required(),
            phase: Yup.object().nullable().required(),
            indication: Yup.object().nullable().required(),
            specialty: Yup.object().nullable().required(),
            patientsObjective: Yup.number().min(1).nullable().required(),
            principalInvestigator: Yup.object().nullable().required(),
        })
    });
}

export const getProtocolPatientsSchema = () => {

    return Yup.object().shape({
        patients: Yup.array()
            .of(
                Yup.object().shape({
                    patient: Yup.object().nullable().required(),
                    numberInProtocol: Yup.string().trim().matches(/^[A-Za-z0-9\-\_]+$/,
                        {
                            message: 'Solo Números, Letras, -, _',
                        }).required(),
                })
            ),
    });
}

export const getProtocolPatientNumberSchema = () => {

    return Yup.object().shape({
        updatedNumberInProtocol: Yup.string().trim().matches(/^[A-Za-z0-9\-\_]+$/,
            {
                message: 'Solo Números, Letras, -, _',
            }).required()
    });
}

export const getProtocolPractitionersSchema = () => {

    return Yup.object().shape({
        practitioners: Yup.array()
            .of(
                Yup.object().shape({
                    practitioner: Yup.object().nullable().required(),
                    studyRole: Yup.object().nullable().required(),
                    studyTasks: Yup.array().nullable(),
                    startOfTask: Yup.date().nullable().required(),
                })
            ),
    });
}

export const getProtocolVisitHeaderSchema = () => {

    return Yup.object().shape({
        visit: Yup.object().shape({
            title: Yup.string().required(),
            type: Yup.object().nullable().required(),
            stage: Yup.object().nullable().required(),
            offsetType: Yup.object().when("offset", (offset) => {
                if (!offset || offset === 0)
                    return Yup.object().nullable();
                else
                    return Yup.object().nullable().required();
            }),
            //offsetFrom: Yup.object().nullable().required(),
            offset: Yup.number().nullable().required(),
            windowNeg: Yup.number().min(0).required(),
            windowPos: Yup.number().min(0).required()
        })
    });
}

export const getValidationSchema = () => {

    let renderable = Yup.lazy(function (item) {
        if (item.hide === false && item.required || item._t === "ArrayField") {
            switch (item._t) {
                case "SingleLineField":
                    return Yup.object().shape({ value: Yup.string().nullable().required() });
                case "MultiLineField":
                    return Yup.object().shape({ value: Yup.string().nullable().required() });
                case "IntegerField":
                    return Yup.object().shape({
                        value: Yup.string().nullable().matches(/^(?<=\s|^)\d+(?=\s|$)/,
                            {
                                message: 'Solo se adminten números enteros',
                            }).required()
                    });
                case "NumberField":
                case "DecimalField":
                    return Yup.object().shape({
                        value: Yup.string().nullable().matches(/^[0-9]\d*(\.\d+)?$/,
                            {
                                message: 'Debe estar expresado con \'.\'',
                            }).required()
                    });
                case "TimeField":
                    return Yup.object().shape({ value: Yup.date().nullable().required() });
                case "DateField":
                    return Yup.object().shape({
                        value: Yup.string().required().matches(/([A-Za-z0-9]{1,3})\/([A-Za-z0-9]{1,3})\/([12][0-9]{3}$)/,
                            {
                                message: 'El año es requerido',
                            }).nullable()
                    });
                case "DateTimeField":
                    return Yup.object().shape({ value: Yup.date().nullable().required() });
                case "AdherenceField":
                case "SelectField":
                case "SelectAsyncField":
                case "SnomedField":
                    return Yup.object().shape({ value: Yup.object().nullable().required() });
                case "YesNoField":
                    return Yup.object().shape({ value: Yup.string().nullable().required() });
                case 'PayableField':
                case "BooleanField":
                    return Yup.object().shape({ value: Yup.boolean().nullable().required() });
                case "RadioField":
                    return Yup.object().shape({ value: Yup.string().nullable().required() });
                case "MultiCheckBoxField":
                    return Yup.object().shape({ value: Yup.array().nullable().required() });
                case "CheckboxField":
                    return Yup.object().shape({ value: Yup.boolean().nullable().required() });
                case "FileUploadField":
                    return Yup.object().shape({ value: Yup.object().nullable().required() });
                    return Yup.object().shape({
                        value: Yup.object().shape({
                            uploadedBy: Yup.string().nullable(),
                            validatedBy: Yup
                                .string()
                                .nullable()
                                .when(["uploadedBy", "validatedBy"], (uploadedBy, validatedBy) => {
                                    if (uploadedBy !== validatedBy)
                                        return Yup.object().nullable().required();
                                    else
                                        return Yup.object().nullable().required();
                                })
                        }).nullable().required()
                    });
                case "ArrayField":
                    return Yup.object().shape({
                        components: Yup.array().of(
                            Yup.object().shape({
                                fields: Yup.array().of(renderable)
                            })
                        )
                    });
                default:
                    return Yup.mixed().notRequired();
            }
        }
        return Yup.mixed().notRequired()
    });

    return Yup.object().shape({
        fields: Yup.array().of(renderable)
    });
}

export const getValidationAuditTrailModal = () => {
    return Yup.object().shape({
        reason: Yup.object().nullable().required(),
        comments: Yup.object()
            .when("reason", (reason) => {
                if (!reason)
                    return Yup.string();
                else
                    return reason.code === "other" ? Yup.string().nullable().required() : Yup.string();
            }),
    });
}

export const getRoleSchema = () => {
    return Yup.object().shape({
        role: Yup.object().shape({
            name: Yup.string().nullable().required(),
            claims: Yup.array().nullable().required(),
        })
    });
}

export const getStudyRoleSchema = () => {
    return Yup.object().shape({
        role: Yup.object().shape({
            name: Yup.string().nullable().required(),
            permissions: Yup.array().nullable(),
        })
    });
}

export const getCodeableConceptSchema = () => {

    return Yup.object().shape({
        studyTask: Yup.object().shape({
            code: Yup.string().nullable().required(),
            title: Yup.string().nullable().required(),
            description: Yup.string().nullable()
        })
    });
}

export const getLaboratoriesSchema = () => {

    return Yup.object().shape({
        laboratory: Yup.object().shape({
            code: Yup.string().nullable().required(),
            title: Yup.string().nullable().required(),
            description: Yup.string().nullable()
        })
    });
}

export const getFreezerSchema = () => {
    return Yup.object().shape({
        freezer: Yup.object().shape({
            Description: Yup.string().nullable().required(),
            Location: Yup.string().nullable(),
            Type: Yup.object().nullable().required(),
            State: Yup.object().nullable().required(),
        })
    })
}

export const getDeviceSchema = () => {
    return Yup.object().shape({
        device: Yup.object().shape({
            Name: Yup.string().nullable().required(),
            State: Yup.object().nullable().required()
        })
    })
}

export const getAssignDeviceSchema = () => {
    return Yup.object().shape({
        freezer: Yup.object().shape({
            FreezerId: Yup.string().nullable().required(),
            Id: Yup.number().min(1).nullable().required(),
        })
    })
}

export const getFreezerConfigurationSchema = () => {
    return Yup.object().shape({
        configuration: Yup.object().shape({
            MinTemperature: Yup.number().required(),
            MaxTemperature: Yup.number().required().when(["MinTemperature"], (MinTemperature, schema) => {
                return schema.min(MinTemperature);
            }),
            AssignedFreezers: Yup.array().of(Yup.string()).optional()
        })
    })
}

export const getDigitalSignSchema = () => {
    return Yup.object().shape({
        password: Yup.string().nullable().required()
    });
}