import isequal from "lodash.isequal";
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Col, Grid, Row } from 'react-bootstrap';
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { CreateAuditModal } from "../../components/AuditTrail/AuditModal";
import { DigitalSign } from '../../components/DigitalSign/DigitalSign';
import { MedicalRecord } from "../../components/Icons/Icons";
import { Actions } from "../../components/MedicalRecords/Actions";
import Entry from '../../components/MedicalRecords/Entry';
import { EntryOptions } from "../../components/MedicalRecords/EntryOptions";
import { PatientInfo2 } from "../../components/MedicalRecords/PatientInfo";
import RouteChangeDetect from "../../components/Modals/RouteChangeDetect";
import { HandleApiError, HandleError, HandleMessagesError } from '../../components/Notifications/APIErrorHandler';
import { Success } from '../../components/Notifications/Notifications';
import { Slider } from "../../components/Slider/Slider";
import { AutoSaving } from "../../components/Utils/AutoSaveForm";
import { getProtocolUrl } from "../../components/Utils/Commons";
import { Spinner } from '../../components/Utils/Loaders';
import { conditions, hcVisitStatus } from "../../variables/Enums";
import { ModalDigitalSign } from "../DigitalSign/ModalDigitalSign";
import { DiagnosisCreateContainer } from "./CreateEntryItems/DiagnosisContainer";
import { EntryItems } from "./CreateEntryItems/EntryItems";
import { ObservationCreateContainer } from "./CreateEntryItems/ObservationContainer";
import { OrdersContainer } from "./CreateEntryItems/OrdersContainer";
import { PrescriptionsContainer } from "./CreateEntryItems/PrescriptionsContainer";
import { useEntry } from './Hooks/useEntries';
import { useModalData } from './Hooks/useModalData';
import { usePatient } from './Hooks/usePatient';
import { EntryItemsListTabs } from "./MedicalRecordItems/EntryItemsList";
import { ModalAddData } from './Modals/ModalAddData';
import { ModalSpinner } from "../../components/Modals/ModalSpinner";
import { ExpandableContent } from "../../components/CustomControls/ExpandableContent";

const CreateEntryContainer = (props) => {
    const { match: { params: { medicalRecordNumber, entryId } } } = props;
    const entryAutoSaveEnabled = useRef(true);
    const detectRouteChangeEnabled = useRef(true);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    let history = useHistory();

    const [isShowingAddData, toggleAddData, addContent, setAddContent] = useModalData();
    const settingsStore = useSelector(state => state.settings);
    const [patient, isPatientLoading] = usePatient(medicalRecordNumber);
    const [entry, isEntryLoading, saveEntry, publishEntry, remove] = useEntry(medicalRecordNumber, entryId);
    const [evolution, setEvolution] = useState(null);
    const [savedValues, setSavedValues] = useState(null);

    const [modal, setModal] = useState(null);
    const [contentToReload, setContentToReload] = useState(null);
    const [showHistory, setShowHistory] = useState(false);

    const hasObservation = useRef(false);
    const hasDiagnosis = useRef(false);

    useEffect(() => {
        if (entry && !isEntryLoading) {
            setEvolution(entry.text || "");
            setSavedValues(entry.text || "");
            hasDiagnosis.current = entry.adverseEvents.find(x => x.category === conditions.diagnosis) !== undefined;
            hasObservation.current = entry.adverseEvents.find(x => x.category === conditions.observation) !== undefined;
        }
    }, [entry, isEntryLoading]);

    //#region Publish Visit
    const _publishVisit = async (entry, pin) => {
        try {
            setModal(<ModalSpinner isShowing={true} hide={null} />);
            entryAutoSaveEnabled.current = false;
            entry.Password = pin?.password;
            await publishEntry(entry);
            entryAutoSaveEnabled.current = true;
            detectRouteChangeEnabled.current = true;
            setIsLoading(false);
            setModal(null);
            Success("medicalRecordsNotifications.entry_Published");
            props.history.push(`/admin/medicalRecords/${medicalRecordNumber}`);
        }
        catch (error) {
            setModal(null);
            entryAutoSaveEnabled.current = true;
            detectRouteChangeEnabled.current = true;
            setIsLoading(false);
            HandleApiError(error);
            console.log(error);
        }
    }

    const _publishAudit = async (reason, comments) => {
        setModal(null);
        setIsLoading(true);
        let entryCopy = JSON.parse(JSON.stringify(entry));
        entryCopy.text = evolution;
        if (reason) {
            entryCopy.auditReason = reason;
            entryCopy.auditComments = comments;
        }

        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                hide={() => { setModal(null); }}
                onSubmit={(pin) => _publishVisit(entryCopy, pin)}
            />);

        else
            _publishVisit(entryCopy)

    }

    const handlePublish = () => {
        let errors = [];
        if (!hasObservation.current) errors.push("medicalRecordsErrors.entryObservationNull");
        if (!evolution) errors.push("medicalRecordsErrors.entryTextNull");
        if (!hasDiagnosis.current) errors.push("medicalRecordsErrors.entryDiagnosisNull");

        if (errors.length > 0) {
            HandleMessagesError("medicalRecordsErrors.EntryRequiredFields", errors);
            return;
        }

        entryAutoSaveEnabled.current = false;
        detectRouteChangeEnabled.current = false;

        if ((entry.status === hcVisitStatus.Published || entry.status === hcVisitStatus.AutoPublished)) {
            setModal(<CreateAuditModal
                onClose={() => { setModal(null); entryAutoSaveEnabled.current = true; detectRouteChangeEnabled.current = true; }}
                onSave={(reason, comments) => _publishAudit(reason, comments)}
            />)
        }
        else {
            _publishAudit();
        }
    }
    //#endregion

    //#region AddItems
    const onModalAddDataSubmit = (contentName) => {
        setContentToReload({
            contentName,
            date: new Date().getTime()
        })
        if (contentName != "orders" && contentName != "prescriptions")
            toggleAddData();
    }
    const onItemSubmit = (contentName, hasCondition) => {
        switch (contentName) {
            case "drugs":
            case "procedures":
                setContentToReload({
                    contentName,
                    date: new Date().getTime()
                })
                break;
            case "diagnoses":
                hasDiagnosis.current = hasCondition;
                setContentToReload({
                    contentName: "conditions",
                    date: new Date().getTime()
                })
                break;
            case "observations": hasObservation.current = hasCondition; break;
            default: break;
        }
    }

    const handleAddClick = (contentName) => {
        setAddContent(contentName);
        toggleAddData();
    }
    //#endregion 

    //#region AutoSave
    const handleAutoSave = async (values) => {
        if (!entryAutoSaveEnabled.current)
            return;

        let entryCopy = JSON.parse(JSON.stringify(entry));
        entryCopy.text = values;
        return saveEntry(entryCopy);
    }
    //#endregion

    //#region Delete
    const handleDeleteWarning = () => {
        setModal(<SweetAlert
            danger
            title={t("medicalRecordsNotifications.confirm_Remove")}
            onConfirm={handleDelete}
            onCancel={() => setModal(null)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="info"
            confirmBtnText={t("buttons.delete")}
            cancelBtnText={t("buttons.back")}
            showCancel
        >
        </SweetAlert>)
    }
    const handleDelete = async () => {
        setModal(null)
        try {
            setIsLoading(true);
            await remove();
            setIsLoading(false);
            props.history.push(`/admin/medicalRecords/${medicalRecordNumber}`);
            Success("medicalRecordsNotifications.entry_Deleted");
        }
        catch (error) {
            setIsLoading(false);
            HandleApiError(error);
            console.log(error);
        }
    }
    //#endregion

    //#region RouteChange
    const handleRouteChange = async (path, saveAndContinue) => {
        if (saveAndContinue) {
            try {
                if (entry.status === hcVisitStatus.Published || entry.status === hcVisitStatus.AutoPublished) {
                    entryAutoSaveEnabled.current = false;
                    if (!evolution) {
                        HandleError("medicalRecordsErrors.EntryTextNull");
                        return;
                    }
                    setModal(<CreateAuditModal
                        onClose={() => {
                            setModal(null);
                            entryAutoSaveEnabled.current = true;
                        }}
                        onSave={async (reason, comments) => {
                            await onConfirmRouteChange(reason, comments, path);
                        }}
                    />)
                }
                else {
                    await handleAutoSave(evolution);
                    history.push(path);
                }
            } catch (error) {
                HandleApiError(error);
                console.log(error)
                return;
            }
        }
        else {
            entryAutoSaveEnabled.current = false;
            history.push(path);
        }
    }
    const onConfirmRouteChange = async (reason, comments, path) => {
        entryAutoSaveEnabled.current = false;
        setModal(null);
        let entryCopy = JSON.parse(JSON.stringify(entry));
        entryCopy.text = evolution;
        if (reason) {
            entryCopy.auditReason = reason;
            entryCopy.auditComments = comments;
        }

        try {
            await publishEntry(entryCopy);
            entryAutoSaveEnabled.current = true;
            Success("medicalRecordsNotifications.entry_Published");
            history.push(path);
        }
        catch (error) {
            entryAutoSaveEnabled.current = true;
            setIsLoading(false);
            HandleApiError(error);
            console.log(error);
        }
    }
    //#endregion

    if (!entry || isEntryLoading || isPatientLoading)
        return <Spinner />;

    if (entry.protocolId)
        return (
            <Alert bsStyle="warning">{t("medicalRecords.textToProtocolarVisit")}&nbsp;
                <NavLink to={`/admin/protocols/${entry.protocolId}/${getProtocolUrl(entry.typeId)}/${entry.visitId}/patient/${entry.numberInProtocol}`} className="nav-link" activeClassName="active">
                    {t("medicalRecords.linkToProtocolarVisit")}
                </NavLink>
            </Alert>
        );

    const disabled = entry.protocolId && true;
    return (
        <div>
            {modal}
            <RouteChangeDetect
                // When should shouldBlockNavigation be invoked, simply passing a boolean (same as "when" prop of Prompt of React-Router)
                when={detectRouteChangeEnabled.current && !isequal(savedValues, evolution)} // Navigate function
                confirmCallback={handleRouteChange}
            />
            <ModalAddData
                medicalRecordNumber={medicalRecordNumber}
                patient={patient}
                entryStatus={entry?.status}
                entryId={entryId}
                isShowing={isShowingAddData}
                hide={toggleAddData}
                contentName={addContent}
                onSubmit={onModalAddDataSubmit}
            />
            <Row>
                <Col md={12}>
                    <PatientInfo2 patient={patient} isLoading={isPatientLoading} />
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    <EntryOptions onClick={handleAddClick} isDisabled={disabled} />
                    <Actions
                        status={entry?.status}
                        onRedirectTo={() => props.history.push(`/admin/medicalRecords/${medicalRecordNumber}`)}
                        onDeleteEntry={handleDeleteWarning}
                        onPublishEntry={handlePublish}
                        onCreateAppointment={() => props.history.push(`/admin/agenda?medicalRecordNumber=${patient?.medicalRecordNumber}&source=${props.location.pathname}`)}
                        isLoading={isLoading}
                        isDisabled={disabled}
                        isProtocol={entry.protocolId}
                    />
                    {
                        settingsStore.settings.digitalSignEnabled &&
                        <DigitalSign signedBy={entry.digitalSign?.signedBy} signStatus={entry.signStatus} signedDate={entry.digitalSign?.signedDate} />
                    }
                </Col>

                <Col md={5}>
                    <ObservationCreateContainer
                        medicalRecordNumber={medicalRecordNumber}
                        entryStatus={entry?.status}
                        entryId={entryId}
                        isDisabled={disabled}
                        onSubmit={onItemSubmit}
                    />
                    <Entry
                        isDisabled={disabled}
                        entry={entry}
                        text={evolution}
                        isLoading={isEntryLoading}
                        onEntryChange={setEvolution}
                        style={{ minHeight: 200, resize: 'vertical' }}
                        rows={4}
                    />
                    <DiagnosisCreateContainer
                        medicalRecordNumber={medicalRecordNumber}
                        isDisabled={disabled}
                        entryStatus={entry?.status}
                        entryId={entryId}
                        contentToReload={contentToReload}
                        onSubmit={onItemSubmit}
                    />
                    <ExpandableContent title={t("medicalRecords.orders.title")} expanded={false} content="Orders">
                        <OrdersContainer
                            isDisabled={disabled}
                            entryStatus={entry?.status}
                            entryId={entryId}
                            medicalRecordNumber={medicalRecordNumber}
                            showAddButton
                            onSubmit={onModalAddDataSubmit}
                            contentToReload={contentToReload}
                        />
                    </ExpandableContent>
                    <ExpandableContent title={t("medicalRecords.prescriptions.title")} expanded={false} content="prescriptions">
                        <PrescriptionsContainer
                            isDisabled={disabled}
                            entryStatus={entry?.status}
                            entryId={entryId}
                            medicalRecordNumber={medicalRecordNumber}
                            showAddButton
                            onSubmit={onModalAddDataSubmit}
                            contentToReload={contentToReload}
                            patient={patient}
                        />
                    </ExpandableContent>

                    {
                        entry?.status !== hcVisitStatus.Published && entry.status !== hcVisitStatus.AutoPublished && savedValues !== null &&
                        <AutoSaving
                            initialValues={savedValues}
                            valuesToSave={evolution}
                            setInitialValues={setSavedValues}
                            onSave={handleAutoSave}
                        />
                    }
                </Col>
                <Col md={5}>
                    <button type="button"
                        className="btn btn-primary btn-fill btn-full"
                        onClick={() => { setShowHistory(!showHistory) }}
                        style={{ marginBottom: '30px', textAlign: 'center' }}>
                        <MedicalRecord /> {t("protocols.visitTracking.viewMR")}
                    </button>
                    <EntryItems
                        medicalRecordNumber={medicalRecordNumber}
                        entryStatus={entry?.status}
                        entryId={entryId}
                        contentToReload={contentToReload}
                        patient={patient}
                        onSubmit={onItemSubmit}
                    />
                </Col>
                <Slider isOpen={showHistory}>
                    <Grid fluid>
                        <button type="button"
                            className="btn btn-primary btn-fill btn-full"
                            onClick={() => { setShowHistory(!showHistory) }}
                            style={{ margin: '30px 0', textAlign: 'center' }}>
                            <MedicalRecord /> {t("protocols.visitTracking.hideMR")}
                        </button>
                        <EntryItemsListTabs medicalRecordNumber={medicalRecordNumber} inProtocol={patient.protocols?.length > 0} />
                    </Grid>
                </Slider>
            </Row>
        </div >
    );
}

export default CreateEntryContainer;