import { useEffect, useState } from 'react';
import { getAdverseEvent, getAdverseEventHistoryAPI, getAdverseEvents, getEvents, postAdverseEvents, putAdverseEvent, } from '../../../api/medicalRecord';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';

export const useEvents = (medicalRecordNumber, entryId, actual) => {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    const [query, setQuery] = useState({
        medicalRecordNumber: medicalRecordNumber ? medicalRecordNumber : 0,
        entryId: entryId ? entryId : 0,
        pagination: {
            page: 1,
            sizePerPage: 10,
            totalSize: 0
        },
        actual: actual ?? false
    });
    const [reload, setReload] = useState(false);

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            try {
                setReload(false);
                setIsLoading(true);
                const paginatedItems = await getEvents(query);

                if (isSubscribed) {
                    setTotalSize(paginatedItems.data.count);
                    setData(paginatedItems.data.data);
                    setIsLoading(false);
                }

            } catch (error) {
                HandleApiError(error);
                setIsLoading(false);
                setTotalSize(0);
                setData([]);
            }
        }

        if (medicalRecordNumber || entryId)
            fetchData();

        return () => isSubscribed = false;
    }, [query, reload]);

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        var newQuery = JSON.parse(JSON.stringify(query));

        switch (type) {
            case 'pagination': {
                newQuery.pagination.page = page;
                newQuery.pagination.sizePerPage = sizePerPage;
                setQuery(newQuery);
                break;
            }
            case 'sort': {
                newQuery.sort.sortField = sortField;
                newQuery.sort.sortOrder = sortOrder;
                setQuery(newQuery);
                break;
            }
            default: break;
        }
    }

    return [data, query, totalSize, isLoading, handleTableChange, setReload];
}

export const useAdverseEvents = (medicalRecordNumber, entryId, includePersonalHistory, actual) => {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    const [query, setQuery] = useState({
        medicalRecordNumber: medicalRecordNumber ? medicalRecordNumber : 0,
        entryId: entryId ? entryId : 0,
        pagination: {
            page: 1,
            sizePerPage: 10,
            totalSize: 0
        },
        includePersonalHistory: includePersonalHistory ?? false,
        actual: actual ?? false
    });
    const [reload, setReload] = useState(false);

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            try {
                setReload(false);
                setIsLoading(true);
                const paginatedItems = await getAdverseEvents(query);

                if (isSubscribed) {
                    setTotalSize(paginatedItems.data.count);
                    setData(paginatedItems.data.data);
                    setIsLoading(false);
                }

            } catch (error) {
                HandleApiError(error);
                setIsLoading(false);
                setTotalSize(0);
                setData([]);
            }
        }

        if (medicalRecordNumber || entryId)
            fetchData();

        return () => isSubscribed = false;
    }, [query, reload]);

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        var newQuery = JSON.parse(JSON.stringify(query));

        switch (type) {
            case 'pagination': {
                newQuery.pagination.page = page;
                newQuery.pagination.sizePerPage = sizePerPage;
                setQuery(newQuery);
                break;
            }
            case 'sort': {
                newQuery.sort.sortField = sortField;
                newQuery.sort.sortOrder = sortOrder;
                setQuery(newQuery);
                break;
            }
            default: break;
        }
    }

    return [data, query, totalSize, isLoading, handleTableChange, setReload];
}

export const useAdverseEvent = (entryId, id) => {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const res = await getAdverseEvent(entryId, id);

                if (isSubscribed) {
                    setData(res.data);
                    setIsLoading(false);
                }

            } catch (error) {
                HandleApiError(error);
                setIsLoading(false);
                setData(null);
            }
        }

        if (id)
            fetchData();

        return () => isSubscribed = false;
    }, [entryId, id]);

    const create = (entryId, data) => {
        return postAdverseEvents(entryId, data);
    }

    const update = (entryId, id, data) => {
        return putAdverseEvent(entryId, id, data);
    }

    return [data, isLoading, create, update];
}

export const useAdverseEventHistory = () => {

    const fetchData = (medicalRecordNumber, conceptId) => {
        try {
            return getAdverseEventHistoryAPI(medicalRecordNumber, conceptId);
        } catch (error) {
            HandleApiError(error);
        }
    }
    return [fetchData];
}