import axios from './index';

export const getPagingAndOrderParams = (newActiveFilters, searchParams) => {

    if (!newActiveFilters)
        return;

    searchParams.delete('pageIndex'); // se resetea el paginado
    if (newActiveFilters.pagination?.page) {
        searchParams.append('pageIndex', newActiveFilters.pagination.page);
    }
    searchParams.delete('pageSize');
    if (newActiveFilters.pagination?.sizePerPage) {
        searchParams.append('pageSize', newActiveFilters.pagination.sizePerPage);
    }
    searchParams.delete('sortField');
    if (newActiveFilters.sort?.sortField) {
        searchParams.append('sortField', newActiveFilters.sort.sortField);
    }
    searchParams.delete('sortOrder');
    if (newActiveFilters.sort?.sortOrder) {
        searchParams.append('sortOrder', newActiveFilters.sort.sortOrder);
    }

    return searchParams;
}

export const getQueryString = (query, search) => {

    var searchParams = new URLSearchParams(search || '');

    if (!query)
        return searchParams.toString();

    searchParams = getPagingAndOrderParams(query, searchParams);

    if (query.filters) {
        for (const property in query.filters) {

            searchParams.delete(property);
            if (query.filters[property] !== null && query.filters[property] !== undefined) {
                searchParams.append(property, query.filters[property]);
            }
        }
    }

    return searchParams.toString();
}

// Admin
export const getMasterData = (items) => {
    const itemsParams = new URLSearchParams(window.location.search || '');
    itemsParams.delete('items');
    items.forEach(item => itemsParams.append('items', item));
    return axios.get(`api/v1/protocols/selectmasterdata?${itemsParams}`);
};

export const getStudyTasks = (protocolId) => {
    return axios.get(`api/v1/protocols/${protocolId}/studyTasks`);
};


// Protocols
export const getProtocols = async (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(queryString ? `api/v1/protocols/?${queryString}` : `api/v1/protocols`);
};
export const getProtocol = async (protocolId) => {
    return axios.get(`api/v1/protocols/${protocolId}`);
};
export const createProtocol = async (protocol) => {
    return axios.post(`api/v1/protocols/`, protocol);
};
export const editProtocol = async (protocolId, protocol) => {
    return axios.put(`api/v1/protocols/${protocolId}`, protocol);
};
export const closeProtocolApi = async (protocolId) => {
    return axios.post(`api/v1/protocols/${protocolId}/close`, null);
};
export const removeProtocolApi = async (protocolId) => {
    return axios.delete(`api/v1/protocols/${protocolId}/remove`, null);
};
/*export const uploadAttachment = async (protocolId, formData) => {
    return axios.post(`api/v1/protocols/${protocolId}/attachments/`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}*/
export const uploadAttachmentEndpoint = (protocolId) => {
    return `api/v1/protocols/${protocolId}/attachments/`;
}
export const getAttachments = (protocolId) => {
    return axios.get(`api/v1/protocols/${protocolId}/attachments`);
};
export const getAttachment = (protocolId, attachmentId) => {
    return axios.get(`api/v1/protocols/${protocolId}/attachments/${attachmentId}`, { responseType: 'blob' });
};
export const getAttachmentEndpoint = (protocolId) => {
    return `api/v1/protocols/${protocolId}/attachments`;
};
export const removeAttachment = (protocolId, attachmentId) => {
    return axios.delete(`api/v1/protocols/${protocolId}/attachments/${attachmentId}`);
};

export const uploadPractitionerAttachment = async (protocolId, practitionerId, formData) => {
    return axios.post(`api/v1/protocols/${protocolId}/practitioners/${practitionerId}/attachments`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
export const getPractitionerAttachmentsEndpoint = (protocolId, practitionerId) => {
    return `api/v1/protocols/${protocolId}/practitioners/${practitionerId}/attachments`;
}

export const getPractitioners = async (protocolId, filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(queryString ? `api/v1/protocols/${protocolId}/practitioners?${queryString}` : `api/v1/protocols/${protocolId}/practitioners`);
};
export const getPractitioner = async (protocolId, practitionerUserId) => {
    return axios.get(`api/v1/protocols/${protocolId}/practitioners/${practitionerUserId}`);
};
export const addPractitioners = async (protocolId, practitioners) => {
    return axios.post(`api/v1/protocols/${protocolId}/practitioners`, practitioners);
};
export const editPractitioner = async (protocolId, practitionerUserId, practitioner) => {
    return axios.put(`api/v1/protocols/${protocolId}/practitioners/${practitionerUserId}`, practitioner);
};
export const removePractitioner = async (protocolId, practitionerId) => {
    return axios.delete(`api/v1/protocols/${protocolId}/practitioners/${practitionerId}`);
};

export const getPatients = async (protocolId, filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(queryString ? `api/v1/protocols/${protocolId}/patients?${queryString}` : `api/v1/protocols/${protocolId}/patients`);
};
export const addPatients = async (protocolId, patients) => {
    return axios.post(`api/v1/protocols/${protocolId}/patients`, patients);
};
export const changePatientNumber = async (protocolId, medicalRecordNumber, patient) => {
    return axios.put(`api/v1/protocols/${protocolId}/patients/${medicalRecordNumber}`, patient);
};
export const removePatient = async (protocolId, numberInProtocol) => {
    return axios.delete(`api/v1/protocols/${protocolId}/patients/${numberInProtocol}`);
};

// Visits
export const getAllVisits = async (protocolId, filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(queryString ? `api/v1/protocols/${protocolId}/visitsTemplates/allversions/?${queryString}` : `api/v1/protocols/${protocolId}/visitsTemplates/allversions`);
};
export const getApprovedVisits = async (protocolId) => {
    return axios.get(`api/v1/protocols/${protocolId}/visitsTemplates`);
};
export const getVisits = async (protocolId) => {
    return axios.get(`api/v1/protocols/${protocolId}/visitsTemplates/all`);
};
export const getVisit = async (protocolId, visitId) => {
    return axios.get(`api/v1/protocols/${protocolId}/visitsTemplates/${visitId}`);
};
export const createVisit = async (protocolId, visit) => {
    return axios.post(`api/v1/protocols/${protocolId}/visitsTemplates/create`, visit);
};
export const copyVisit = async (protocolId, visitId, visit) => {
    return axios.post(`api/v1/protocols/${protocolId}/visitsTemplates/${visitId}/copy`, visit);
};
export const editVisit = async (protocolId, visitId, visit) => {
    return axios.put(`api/v1/protocols/${protocolId}/visitsTemplates/${visitId}`, visit);
};
export const removeVisit = async (protocolId, visitId) => {
    return axios.delete(`api/v1/protocols/${protocolId}/visitsTemplates/${visitId}`);
};
export const swapVisits = async (protocolId, visitId, visitToSwapId) => {
    return axios.put(`api/v1/protocols/${protocolId}/visitsTemplates/${visitId}/swap/${visitToSwapId}`);
}
export const getVisitHistory = async (protocolId, visitId) => {
    return axios.get(`api/v1/protocols/${protocolId}/visitsTemplates/${visitId}/history`);
};
export const publishVisit = async (protocolId, visitId, comments) => {
    return axios.post(`api/v1/protocols/${protocolId}/visitsTemplates/${visitId}/history/${comments}`);
};
export const printVisit = async (protocolId, visitId) => {
    return axios.get(`api/v1/protocols/${protocolId}/visitsTemplates/${visitId}/export`, {
        responseType: 'blob',
        timeout: 30000,
    });
};

// Forms
export const getForms = async (protocolId, visitId) => {
    return axios.get(`api/v1/protocols/${protocolId}/visitsTemplates/${visitId}/forms`);
};
export const getForm = (protocolId, visitId, formId) => {
    return axios.get(`api/v1/protocols/${protocolId}/visitsTemplates/${visitId}/forms/${formId}`);
};
export const createForm = (protocolId, visitId, form) => {
    return axios.post(`api/v1/protocols/${protocolId}/visitsTemplates/${visitId}/forms/create`, form);
};
export const editForm = (protocolId, visitId, formId, form) => {
    return axios.put(`api/v1/protocols/${protocolId}/visitsTemplates/${visitId}/forms/${formId}`, form);
};
export const removeForm = (protocolId, visitId, formId) => {
    return axios.delete(`api/v1/protocols/${protocolId}/visitsTemplates/${visitId}/forms/${formId}`);
};
export const sortForms = async (protocolId, visitId, sortedForms) => {
    return axios.put(`api/v1/protocols/${protocolId}/visitsTemplates/${visitId}/forms/sort`, sortedForms);
}


//#region Visit Tracking
export const getVisitsTracking = async (protocolId, query) => {
    const queryString = getQueryString(query, window.location.search);
    return axios.get(queryString ? `api/v1/visitTracking/${protocolId}/visitTracking?${queryString}` : `api/v1/visitTracking/${protocolId}/visitTracking`);
};
export const getVisitTracking = async (protocolId, visitId, patientId) => {
    return axios.get(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}`);
};
export const getPreviousVisitTracking = async (protocolId, visitId, patientId) => {
    return axios.get(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}/prev`);
};
export const getVisitTrackingForms = async (protocolId, visitId, patientId) => {
    return axios.get(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}/forms`);
};
export const getVisitTrackingForm = async (protocolId, visitId, formId) => {
    return axios.get(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/forms/${formId}`);
};
export const createVisitTracking = async (protocolId, visitId, patientId) => {
    return axios.post(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}`, {});
};
export const saveVisitTracking = async (protocolId, visitId, patientId, form) => {
    return axios.put(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}`, form);
};
export const saveUploadVisitTrackingAPI = async (protocolId, visitId, patientId, form) => {
    return axios.put(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}/saveUpload`, form);
};
export const publishVisitTracking = async (protocolId, visitId, patientId, digitalSign) => {
    return axios.put(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}/publish`, digitalSign);
};
export const skipVisitTracking = async (protocolId, visitId, patientId, digitalSign) => {
    return axios.put(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}/skip`, digitalSign);
};
export const screenFailureVisitTracking = async (protocolId, visitId, patientId, digitalSign) => {
    return axios.put(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}/screenFailure`, digitalSign);
};
export const lostToFollowUpVisitTracking = async (protocolId, visitId, patientId, digitalSign) => {
    return axios.put(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}/lostToFollowUp`, digitalSign);
};
export const discontinuationVisitTracking = async (protocolId, visitId, patientId, digitalSign) => {
    return axios.put(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}/discontinuation`, digitalSign);
};
export const withoutDrugsVisitTracking = async (protocolId, visitId, patientId) => {
    return axios.put(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}/withoutDrugs`, null);
};
export const returnDrugsVisitTracking = async (protocolId, visitId, patientId) => {
    return axios.put(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}/returnDrugs`, null);
};
export const reSyncVisitTrackingAPI = async (protocolId, visitId, patientId, digitalSign) => {
    return axios.put(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}/resync`, digitalSign);
};
export const changeTypeVisitTrackingAPI = async (protocolId, visitId, patientId, type) => {
    return axios.put(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}/changeType/${type}`, null);
};
export const changeVisitTrackingTypeAPI = async (protocolId, visitId, patientId, entryId, visitTrackingId) => {
    return axios.put(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}/entry/${entryId}/visit/${visitTrackingId}/changeVisitTrackingType`, null);
};
export const removeVisitTrackingAPI = async (protocolId, visitId, patientId) => {
    return axios.delete(`api/v1/visitTracking/protocols/${protocolId}/visitTracking/${visitId}/patient/${patientId}`);
};

export const uploadVisitTrackingAttachmentFormEndpoint = (protocolId, visitId, formId, patientId) => `api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}/attachments/${formId}`;

export const getVisitTrackingAttachmentFormEndpoint = (protocolId, visitId, formId) => `api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/attachments/${formId}/fileName`;

//#endregion

// #region ExtraVisitTracking
export const getVisitTrackingExtraForms = async (protocolId, visitId, patientId) => {
    return axios.get(`api/v1/visitTracking/${protocolId}/visitTrackingExtra/${visitId}/patient/${patientId}/forms`);
};
export const createVisitTrackingExtra = async (protocolId, patientId, vm) => {
    return axios.post(`api/v1/visitTracking/${protocolId}/visitTrackingExtra/patient/${patientId}`, vm);
};
export const saveVisitTrackingExtraForm = async (protocolId, visitId, patientId, form) => {
    return axios.put(`api/v1/visitTracking/${protocolId}/visitTrackingExtra/${visitId}/patient/${patientId}`, form);
};
export const publishVisitTrackingExtraForm = async (protocolId, visitId, patientId, type, form) => {
    return axios.put(`api/v1/visitTracking/${protocolId}/visitTrackingExtra/${visitId}/patient/${patientId}/publish/${type}`, form);
};
export const screenFailureVisitTrackingExtra = async (protocolId, visitId, patientId, digitalSign) => {
    return axios.put(`api/v1/visitTracking/${protocolId}/visitTrackingExtra/${visitId}/patient/${patientId}/screenFailure`, digitalSign);
};

export const removeVisitTrackingExtraAPI = async (protocolId, visitId, patientId) => {
    return axios.delete(`api/v1/visitTracking/protocols/${protocolId}/visitTrackingExtra/${visitId}/patient/${patientId}`);
};
export const uploadVisitTrackingExtraAttachmentFormEndpoint = (protocolId, visitId, formId, patientId) => `api/v1/visitTracking/${protocolId}/visitTrackingExtra/${visitId}/patient/${patientId}/attachments/${formId}`;
//#endregion

export const getVisitTrackingAdverseEvents = async (protocolId, visitId, patientId) => {
    return axios.get(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}/adverseEvents`);
};
export const exportVisitTrackingAPI = async (protocolId, visitId, patientId, pin) => {
    return axios.get(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}/export/${btoa(pin)}`, { responseType: 'blob' });
};
export const updateEvolutionAPI = async (protocolId, visitId, patientId, evolutionId, evolution) => {
    return axios.put(`api/v1/visitTracking/${protocolId}/visitTracking/${visitId}/patient/${patientId}/evolution/${evolutionId}`, evolution);
};
export const updateFormRestrictionsAPI = async (protocolId, restrictions) => {
    return axios.put(`api/v1/visitTracking/${protocolId}/formRestrictions`, restrictions);
};


//#region DocumentNotes
export const getDocumentNotes = async (protocolId, documentId) => {
    return axios.get(`api/v1/visitTracking/DocumentNotes/${documentId}/protocol/${protocolId}`);
};
export const getDocumentNote = async (protocolId, noteId) => {
    return axios.get(`api/v1/visitTracking/DocumentNotes/protocol/${protocolId}/note/${noteId}`);
};
export const createDocumentNote = async (documentId, protocolId, visitId, patientId, note) => {
    return axios.post(`api/v1/visitTracking/DocumentNotes/${documentId}/protocol/${protocolId}/visit/${visitId}/patient/${patientId}`, note);
};
export const updateDocumentNote = async (protocolId, noteId, note) => {
    return axios.put(`api/v1/visitTracking/DocumentNotes/protocol/${protocolId}/note/${noteId}`, note);
};
//#endregion

//#region MonitorNotes
export const getExternalNotes = async (protocolId, filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(queryString ? `api/v1/visitTracking/DocumentNotes/monitornote/protocol/${protocolId}?${queryString}` : `api/v1/visitTracking/DocumentNotes/monitornote/protocol/${protocolId}`);
};
export const getMonitorNotes = async (protocolId, documentId) => {
    return axios.get(`api/v1/visitTracking/DocumentNotes/monitornote/${documentId}/protocol/${protocolId}`);
};
export const getMonitorNote = async (protocolId, noteId) => {
    return axios.get(`api/v1/visitTracking/DocumentNotes/protocol/${protocolId}/monitornote/${noteId}`);
};
export const createMonitorNote = async (documentId, protocolId, visitId, patientId, note) => {
    return axios.post(`api/v1/visitTracking/DocumentNotes/monitornote/${documentId}/protocol/${protocolId}/visit/${visitId}/patient/${patientId}`, note);
};
export const updateMonitorNote = async (protocolId, noteId, note) => {
    return axios.put(`api/v1/visitTracking/DocumentNotes/protocol/${protocolId}/monitornote/${noteId}`, note);
};
export const changeStatusMonitorNote = async (protocolId, noteId, note) => {
    return axios.put(`api/v1/visitTracking/DocumentNotes/protocol/${protocolId}/monitornote/${noteId}/status`, note);
};
export const removeMonitorNote = async (protocolId, noteId) => {
    return axios.delete(`api/v1/visitTracking/DocumentNotes/protocol/${protocolId}/monitornote/${noteId}`);
};
//#endregion