import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import Card from '../../../components/Cards/Card';
import { AuditTooltip } from '../../../components/CustomControls/AuditTooltip';
import { Entry, EvolutionEdit, Evolutions } from '../../../components/Protocols/Tracking/Entry';
import { useEvolutions } from './useEvolutions';
import { visitStatus } from '../../../variables/Enums';
import { CreateAuditModal } from '../../../components/AuditTrail/AuditModal';
import { HandleApiError, HandleMessageError } from '../../../components/Notifications/APIErrorHandler';
import { Success } from '../../../components/Notifications/Notifications';

export const EvolutionsContainer = (props) => {
    const { data, audit, title, subtitle, isDisabled, visitTrackingStatus } = props;
    const [isLoading, updateEvolution] = useEvolutions();
    const [evolution, setEvolution] = useState(null);
    const [evolutions, setEvolutions] = useState(data);
    const [modal, setModal] = useState(null);

    useEffect(
        () => setEvolutions(data),
        [data]
    );

    const handleEditEvolution = (evolutionId) => {
        var evolutionEdit = evolutions.filter(x => (x.id === evolutionId));
        if (evolutionEdit.length > 0)
            setEvolution(evolutionEdit[0]);
        else
            HandleMessageError("protocolNotifications.visitTracking_evolutionNotFound");
    }

    const handleEvolutionEdited = async (evolutionId, text) => {
        if (visitTrackingStatus !== visitStatus.Started) {
            setModal(<CreateAuditModal
                onClose={() => setModal(null)}
                onSave={(reason, comments) => _saveEvolution(evolutionId, text, { reason: reason, comments: comments })}
            />)
        }
        else {
            _saveEvolution(evolutionId, text);
        }
    }

    const _saveEvolution = async (evolutionId, text, audit) => {

        try {
            var updatedEvolution = await updateEvolution(props.protocolId, props.visitId, props.patientId, evolutionId, text, audit);
            if (updatedEvolution) {
                let evolutionsCopy = JSON.parse(JSON.stringify(evolutions));
                var index = evolutions.findIndex(x => x.id === evolutionId);
                evolutionsCopy.splice(index, 1, updatedEvolution.data); //Reemplazo. 
                setEvolutions(evolutionsCopy);
                setEvolution(null);
                setModal(null);
                Success("protocolNotifications.visitTracking_evolutionUpdated");
            }
        }
        catch (error) {
            setModal(null);
            console.error(error);
            HandleApiError(error);
        }
    }

    return (
        <Card
            title={title}
            subtitle={subtitle}
            content={
                <>
                    {audit && <AuditTooltip id="visitText" text={audit} />}
                    {modal}
                    {
                        !isDisabled && !evolution &&

                        <Entry
                            {...props}
                            isDisabled={isDisabled}
                        />
                    }
                    {
                        evolution &&
                        <EvolutionEdit
                            evolution={evolution}
                            onSave={handleEvolutionEdited}
                            onCancel={() => setEvolution(null)}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                        />
                    }
                    <Evolutions
                        data={evolutions}
                        isLoading={false}
                        onEdit={handleEditEvolution}
                    />
                </>
            }
        />
    );
}

EvolutionsContainer.defaultProps = {
    //title: "Evolución"
}
EvolutionsContainer.propTypes = {
    title: PropTypes.object,
    subtitle: PropTypes.object,
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
};