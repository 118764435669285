import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from 'react-i18next';
import { putAdverseEvent } from "../../../api/medicalRecord";
import { AuditModal, CreateAuditModal } from "../../../components/AuditTrail/AuditModal";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { AdverseEvents } from "../../../components/MedicalRecords/AdverseEvents";
import { AdverseEventForm } from "../../../components/MedicalRecords/Forms/AdverseEventsForm";
import { ModalData } from '../../../components/Modals/ModalData';
import { getMedicalRecordsSchema } from "../../../components/Validations/FormValidationSchema";
import { AuditTrail } from "../../Admin/AuditTrail";
import { useAdverseEvent } from "../Hooks/useAdverseEvents";
import { useCondition, useConditions } from '../Hooks/useConditions';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { DiagnosisContainerFormik } from './DiagnosisContainer';

export const ConditionsContainer = (props) => {
    const [content, setContent] = useState(null);
    const { medicalRecordNumber, entryStatus, entryId, expandedAll } = props;
    const [conditions, query, totalSize, isLoading, handleTableChange, setReload] = useConditions(medicalRecordNumber, entryId, null, true);
    const [, , , , copy] = useCondition(entryId, null)
    const { t } = useTranslation();

    useEffect(() => {
        if (props.contentToReload?.contentName === "conditions")
            setReload(true);

    }, [props.contentToReload]);

    const handleEdit = (id) => {
        setContent(<AdverseEventEditContainer
            entryId={entryId}
            id={id}
            onSubmit={handleSubmit}
            onCancel={handleClose}
        />);
    }

    const handleSubmit = async (data) => {

        if (!data || !data.adverseEvents || data.adverseEvents.length === 0)
            return;

        if (entryStatus !== "Published" && entryStatus !== "AutoPublished" && entryId == data.adverseEvents[0].entryId && (!data.adverseEvents[0].originalId || data.adverseEvents[0].id == data.adverseEvents[0].originalId)) {
            onConfirmSubmit(data);
        } else {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (data && data.adverseEvents && data.adverseEvents.length > 0) {
                let adverseEvent = data.adverseEvents[0];
                if (reason) {
                    adverseEvent.auditReason = reason;
                    adverseEvent.auditComments = comments;
                }
                await putAdverseEvent(entryId, adverseEvent.id, adverseEvent);
                setContent(null);
                //setReload(true);
                if (props.onSubmit)
                    props.onSubmit("diagnoses", true);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleClose = () => { setContent(null); }

    const handleAuditTrail = (entityId) => {
        setContent(
            <AuditModal
                onClose={() => { setContent(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditTrail entity="AdverseEvents" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    const handleAddDiagnosis = (item) => {
        setContent(
            <ModalData
                title={`${t("commons.add")} '${item.adverseEffect}'`}
                isShowing={true}
                size="medium"
                className=""
                hide={handleClose}
            >
                <DiagnosisContainerFormik
                    onSubmit={handleSubmitDiagnosis}
                    onCancel={handleClose}
                    condition={item} />
            </ModalData>
        );
    }

    const handleSubmitDiagnosis = (data) => {
        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmitDiagnosis(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmitDiagnosis(data);
        }
    }

    const onConfirmSubmitDiagnosis = async (data, reason, comments) => {
        handleClose();

        if (!data || !data.condition) return;

        if (reason) {
            data.auditReason = reason;
            data.auditComments = comments;
        }

        try {
            await copy(entryId, data.condition.id, data);
            // Llamo a refresh para que se actualice el componente Diagnoses. 
            if (props.onSubmit)
                props.onSubmit("diagnoses", true)
        }
        catch (error) {
            HandleApiError(error);
        }
    }

    if (totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.condition.title_open")} expandedAll={expandedAll} content="adverseEvents">
            {content}
            <AdverseEvents
                data={conditions}
                entryId={entryId}
                withCard={false}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onEdit={handleEdit}
                onAuditTrail={handleAuditTrail}
                onAddDiagnosis={handleAddDiagnosis}
            />
        </ExpandableContent>
    );
}

const AdverseEventEditContainer = (props) => {
    const { entryId, id, onSubmit, onCancel } = props;
    const [data, isLoading] = useAdverseEvent(entryId, id);

    if (!data || isLoading)
        return null;

    return (
        <AdverseEventContainerFormik
            onSubmit={onSubmit}
            onCancel={onCancel}
            adverseEvent={data}
        />
    );
}

const AdverseEventContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => (props.adverseEvent && { adverseEvents: [props.adverseEvent] }),

    validationSchema: getMedicalRecordsSchema().adverseEvent,

    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },

    displayName: 'ConditionsForm',

})(AdverseEventForm));

AdverseEventContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    adverseEvent: PropTypes.object.isRequired,
};