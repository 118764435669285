import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AuditModal } from "../../../components/AuditTrail/AuditModal";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { Orders } from "../../../components/MedicalRecords/Orders";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { AuditTrail } from "../../Admin/AuditTrail";
import { useOrder, useOrders } from "../Hooks/useOrders";

export const OrdersListContainer = ({ medicalRecordNumber, entryId, expandedAll }) => {
    const { t } = useTranslation();
    const [data, query, totalSize, isLoading, handleTableChange,] = useOrders(medicalRecordNumber, entryId);
    const [, , , , , print] = useOrder();

    const [modal, setModal] = useState(null);
    const handleAuditTrail = (entityId) => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditTrail entity="OrdersItems" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    const handlePrint = async (entryId2, id) => {
        try {
            var response = await print(medicalRecordNumber, entryId2, id, null);

            const blob = response.data;
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            //Success("medicalRecordsNotifications.entry_Exported");
        }
        catch (error) {
            HandleApiError(error);
            console.log(error);
        };
    }

    if (isLoading || totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.orders.title")} content="orders" expandedAll={expandedAll}>
            {modal}
            <Orders
                withCard={false}
                data={data}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onAuditTrail={handleAuditTrail}
                onPrint={handlePrint}
            />
        </ExpandableContent>
    );
}