import React from "react";
import { useTranslation } from "react-i18next";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";

import { PersonalHabits } from "../../../components/MedicalRecords/PersonalHabits";
import { usePersonalHabits } from "../Hooks/usePersonalHabits";

export const PersonalHabitsListContainer = ({ entryId, medicalRecordNumber, expandedAll }) => {
    const { t } = useTranslation();
    const [personalHabits, isLoading] = usePersonalHabits(medicalRecordNumber, entryId);

    if (!personalHabits || personalHabits.length === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.personalHabits.title")} content="personalHabits" expandedAll={expandedAll}>
            <PersonalHabits
                withCard={false}
                data={personalHabits}
                isLoading={isLoading}
            />
        </ExpandableContent>
    );
}