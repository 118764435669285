import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from "react";
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RichTextControl } from '../../CustomControls/RichTextControl';
import { SignWithOutInfo } from '../../DigitalSign/Sign';
import { Add, Edit, RemoveCircle, Save, Signature } from '../../Icons/Icons';
import { Spinner } from '../../Utils/Loaders';
import { Popover } from '../../Utils/Popover';
import { FormErrorMessage } from '../../Validations/FormValidationErrors';
import { toLocaleDateFormatter } from '../../Utils/Formatter';

export const Entry = (props) => {
    const {
        values,
        setFieldValue,
        setFieldTouched,
        isDisabled,
        placeholder
    } = props;

    const [collapse, setCollapse] = useState(values.status === 1 ? true : false);

    return (
        <div>
            <div style={{ textAlign: 'end' }}>
                <button className="btn-table-actions" type="button" onClick={() => setCollapse(!collapse)}>
                    {collapse ? <Add /> : <RemoveCircle />}
                </button>
            </div>
            {
                !collapse &&
                <div style={{ marginBottom: '10px' }}>
                    <RichTextControl
                        autoFocus={true}
                        name="text"
                        isDisabled={isDisabled}
                        placeholder={placeholder}
                        height={props.height}
                        value={values.text ?? ""}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        toolbar={
                            [
                                ['clean']
                            ]
                        }
                    />
                </div>
            }
            <ErrorMessage name={`text`} component={FormErrorMessage} />
        </div>
    );
}

Entry.defaultProps = {
    height: 300
}

Entry.propTypes = {
    height: PropTypes.number,
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
};

export const EvolutionEdit = (props) => {
    const {
        evolution,
        onSave,
        onCancel,
        isDisabled,
        isLoading
    } = props;

    const { t } = useTranslation();
    const [data, setData] = useState(evolution?.text);

    const handleSave = () => { if (onSave && evolution && data) onSave(evolution?.id, data); }
    const handleCancel = () => { if (onCancel) onCancel(); }

    if (isLoading)
        return <Spinner />

    return (
        <div>
            <RichTextControl
                autoFocus={true}
                name="evolution"
                isDisabled={isDisabled}
                style={props.style}
                height={props.height}
                value={data ?? ""}
                onChange={(_, value) => setData(value)}
                onBlur={() => { }}
                toolbar={
                    [
                        ['clean']
                    ]
                }
            />
            <Grid fluid>
                <Row>
                    <Col sm={12}>
                        <button type="button" className="btn btn-success btn-right" onClick={handleSave} ><Save /> {t("buttons.save")}</button>
                        <button type="button" className="btn btn-default btn-right" onClick={handleCancel} style={{ marginRight: '10px' }}>{t("buttons.cancel")}</button>
                    </Col>
                </Row>
            </Grid>
        </div>
    );
}

Entry.defaultProps = {
    height: 300
}

Entry.propTypes = {
    height: PropTypes.number,
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
};


export const Evolutions = (props) => {
    const { data, onEdit } = props;
    const { t } = useTranslation();

    return (<Grid fluid key="evolution-items" className="evolution-items draft">
        {
            data && data.length > 0 ?
                data.map((item, idx) => (
                    <Row key={`evolution-entry-${idx}`}>
                        <Col md={12} className="evolution-item">
                            <div>
                                <button type="button" className="btn-table-actions" onClick={() => onEdit(item.id)}>
                                    <Edit />
                                </button>
                                <Popover
                                    id={`sign-${idx}`}
                                    key={`sign-${idx}`}
                                    cssClass="popover-sign"
                                    popover={<SignWithOutInfo signedBy={item.createdById} created={item.created} />}
                                >
                                    <Signature style={{ fontSize: '25px', color: '#50ABB5', cursor: 'hand' }} />
                                </Popover>
                                <span className="w900">{toLocaleDateFormatter(item.created, "DD/MM/YYYY HH:mm")} [{item.createdBy?.fullName} - {item.createdBy?.speciality}]:</span>
                                <span dangerouslySetInnerHTML={{ __html: item.text }} />
                                {
                                    item.modified !== item.created &&
                                    <span style={{ fontStyle: "italic" }}>[Modificado el {toLocaleDateFormatter(item.modified, "DD/MM/YYYY HH:mm")} por {item.modifiedBy?.fullName} - {item.createdBy?.speciality}]</span>
                                }
                            </div>
                        </Col>
                    </Row>

                ))
                :
                <p style={{ textAlign: "center" }}>
                    <b>{t("medicalRecords.entries.noentries")}</b>
                </p>
        }
    </Grid>
    );
}

Evolutions.propTypes = {
    data: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired
};