import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from 'react-i18next';
import { putPersonalHabit } from "../../../api/medicalRecord";
import { AuditModal, CreateAudit, CreateAuditModal } from "../../../components/AuditTrail/AuditModal";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { PersonalHabitsForm } from "../../../components/MedicalRecords/Forms/PersonalHabitsForm";
import { PersonalHabits } from "../../../components/MedicalRecords/PersonalHabits";
import { AuditTrail } from "../../Admin/AuditTrail";
import { usePersonalHabit, usePersonalHabits } from "../Hooks/usePersonalHabits";

export const PersonalHabitsContainer = (props) => {
    const [content, setContent] = useState(null);
    const { medicalRecordNumber, entryStatus, entryId, expandedAll } = props;
    const [personalHabits, isLoading, setReload] = usePersonalHabits(medicalRecordNumber);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.contentToReload?.contentName === "personalHabits")
            setReload(true);
    }, [props.contentToReload]);

    const handleEdit = (id) => {
        setContent(<PersonalHabitEditContainer
            entryId={entryId}
            id={id}
            onSubmit={handleSubmit}
            onCancel={handleClose}
        />);
    }

    const handleSubmit = async (data) => {

        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (reason) {
                data.auditReason = reason;
                data.auditComments = comments;
            }
            await putPersonalHabit(entryId, data.id, data);
            setContent(null);
            setReload(true);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleClose = () => {
        setContent(null);
    }

    const handleAuditTrail = (entityId) => {
        setContent(
            <AuditModal
                onClose={() => { setContent(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditTrail entity="PersonalHabits" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    if (personalHabits || personalHabits.length === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.personalHabits.title")} expandedAll={expandedAll} content="personalHabits">
            {content}
            <PersonalHabits
                withCard={false}
                entryId={entryId}
                data={personalHabits}
                isLoading={isLoading}
                onEdit={!props.readOnly ? handleEdit : null}
                onAuditTrail={props.readOnly ? handleAuditTrail : null}
            />
        </ExpandableContent>
    );
}

export const PersonalHabitCreateContainer = (props) => {
    const { entryStatus, entryId, onSubmit } = props;
    const [, , create] = usePersonalHabit(entryId);
    const [content, setContent] = useState(null);

    const handleSubmit = async (data) => {

        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAudit
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (reason) {
                data.auditReason = reason;
                data.auditComments = comments;
            }
            await create(entryId, data);
            setContent(null);
            onSubmit("personalHabits")
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleCancel = () => { props.hide() }

    return (
        <>
            {
                content ??
                <PersonalHabitContainerFormik
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                />
            }
        </>

    );
}

const PersonalHabitEditContainer = (props) => {
    const { entryId, id, onSubmit } = props;
    const [data, isLoading] = usePersonalHabit(entryId, id);

    if (!data || isLoading)
        return null;

    return (
        <PersonalHabitContainerFormik
            onSubmit={onSubmit}
            onCancel={props.onCancel}
            personalHabit={data}
        />
    );
}

const PersonalHabitContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => (props.personalHabit),

    //validationSchema: getProtocolBriefSchema(),

    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },

    displayName: 'PersonalHabitsForm',

})(PersonalHabitsForm));

PersonalHabitContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    personalHabit: PropTypes.object,
};