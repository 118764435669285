import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, ControlLabel, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAuthorization } from '../Authorization/useAuthorization';
import { TextBoxUsersMentions } from '../CustomControls/TextBoxUsersMentions';
import { Add, Edit, Remove, Save, Enabled } from '../Icons/Icons';
import { getFullNameFromUserMention } from '../Utils/Commons';
import { dateTimeFormatter } from '../Utils/Formatter';
import { ConfirmRemove, RejectExternalNote } from './Admin/Modals';
import { noteEnum } from '../../variables/Enums';
import { Tooltip } from '../Utils/Tooltips';

export const MonitorNotes = (props) => {
    const [, user] = useAuthorization();
    const [modal, setModal] = useState(null);
    const { t } = useTranslation();

    if (!props.notes)
        return null;

    const handleEdit = (noteId) => {
        if (props.onEdit)
            props.onEdit(noteId);
    }

    const handleChangeState = (noteId, status) => {
        if (!props.onStatusChange)
            return;

        if (status === noteEnum.Invalid)
            setModal(
                <RejectExternalNote
                    onClose={() => setModal(null)}
                    onConfirm={(confirmChangeState)}
                    id={noteId}
                    status={status}
                />
            );
        else
            props.onStatusChange(noteId, status);
    }
    const confirmChangeState = (id, status, response) => {
        setModal(null);
        props.onStatusChange(id, status, response);
    }

    const handleRemove = (noteId) => {
        if (props.onRemove)
            setModal(
                <ConfirmRemove
                    onClose={() => setModal(null)}
                    onConfirm={(confirmRemove)}
                    id={noteId}
                />
            );
    }
    const confirmRemove = (id) => {
        setModal(null);
        props.onRemove(id);
    }

    return (
        <Grid fluid>
            {modal}
            {
                props.notes.sort(function (a, b) {
                    return new Date(b.created) - new Date(a.created);
                }).map((note, index) => (
                    <Row key={`note-idx-${index}`} style={{ padding: '10px 0', borderBottom: '1px solid #eee' }}>
                        <Col sm={3}>
                            <div>{dateTimeFormatter(note.created)}</div>
                            <div>{note.createdBy}</div>
                        </Col>
                        <Col sm={7}>
                            <div>{getFullNameFromUserMention(note.note)}</div>
                        </Col>
                        {
                            user?.sub === note.createdById ?
                                <Col sm={2}>
                                    <button className="btn-table-actions" type="button" onClick={() => handleEdit(note.id)}><Edit /></button>
                                    <button className="btn-table-actions btn-remove" type="button" onClick={() => handleRemove(note.id)}><Remove /></button>
                                </Col> :
                                <Col sm={2}>
                                    <button className="btn-table-actions" type="button" onClick={() => handleChangeState(note.id, noteEnum.Fixed)}>
                                        <Tooltip tooltip={t("notes.status.fixed")} id={"tooltipstatusfixed-" + index}>
                                            <Enabled />
                                        </Tooltip>
                                    </button>
                                    <button className="btn-table-actions btn-remove" type="button" onClick={() => handleChangeState(note.id, noteEnum.Invalid)}>
                                        <Tooltip tooltip={t("notes.status.invalid")} id={"tooltipstatusinvalid-" + index}>
                                            <Remove />
                                        </Tooltip>
                                    </button>
                                </Col>
                        }
                    </Row>
                ))
            }
        </Grid>
    );
}

MonitorNotes.propTypes = {
    notes: PropTypes.array.isRequired,
    documentId: PropTypes.number.isRequired,
    onEdit: PropTypes.func.isRequired
};

export const MonitorNoteForm = ({ onCreate, onEdit, note }) => {
    const { t } = useTranslation();
    const [text, setText] = useState("");

    useEffect(() => {
        if (note)
            setText(note.note);
    }, [note]);

    const handleChange = (_name, value) => {
        setText(value);
    }

    const handleCreate = () => {
        if (onCreate)
            onCreate(text);
        setText("");
    }

    const handleEdit = () => {
        if (onEdit) {
            onEdit(note.id, text);
        }
        setText("");
    }

    return (
        <Grid fluid>
            <Row>
                <Col sm={12}>
                    <ControlLabel>{t("protocols.visitTracking.documentNotes")}</ControlLabel>
                </Col>
                <Col sm={10}>
                    <TextBoxUsersMentions name="notes" value={text} onChange={handleChange} />
                </Col>
                <Col sm={2}>
                    {
                        note
                            ?
                            <button className="btn-table-actions" style={{ fontSize: '24px', fontWeight: '700', lineHeight: '1' }} type="button" onClick={handleEdit}><Save /></button>
                            :
                            <button className="btn-table-actions" style={{ fontSize: '24px', fontWeight: '700', lineHeight: '1' }} type="button" onClick={handleCreate}><Add /></button>
                    }
                </Col>
            </Row>
        </Grid >
    );
}

MonitorNoteForm.propTypes = {
    note: PropTypes.object,
    onCreate: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired
};