import React, { useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { DigitalSign } from '../../components/DigitalSign/DigitalSign.jsx';
import { SignWithOutInfo } from '../../components/DigitalSign/Sign.js';
import { Actions } from "../../components/MedicalRecords/Actions.jsx";
import { EntryNav } from "../../components/MedicalRecords/EntryNav";
import EntryView from '../../components/MedicalRecords/EntryView';
import { PatientInfo2 } from "../../components/MedicalRecords/PatientInfo";
import { HandleApiError } from '../../components/Notifications/APIErrorHandler.js';
import { Success } from '../../components/Notifications/Notifications';
import { getProtocolUrl } from '../../components/Utils/Commons.jsx';
import { Spinner } from '../../components/Utils/Loaders.jsx';
import { useAudits } from '../Protocols/Hooks.js';
import { DiagnosesContainer } from './CreateEntryItems/DiagnosisContainer.js';
import { ObservationsContainer } from './CreateEntryItems/ObservationContainer.js';
import { useEntries, useEntry } from './Hooks/useEntries';
import { useModalData } from './Hooks/useModalData.js';
import { usePatient } from './Hooks/usePatient';
import { EntryItemsList } from './MedicalRecordItems/EntryItemsList.jsx';
import { OrdersListContainer } from './MedicalRecordItems/OrdersListContainer.js';
import { PrescriptionsListContainer } from './MedicalRecordItems/PrescriptionsListContainer.js';

export const ViewEntryContainer = (props) => {
    const { match: { params } } = props;
    const { medicalRecordNumber, entryId } = params;
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const [patient, isPatientLoading] = usePatient(medicalRecordNumber);
    const [entry, isEntryLoading, , , , , exportToPDF] = useEntry(medicalRecordNumber, entryId);
    const [isAuditLoading, , getLastChange] = useAudits("Entry", entryId);
    const [isShowingDigitalSign, toggleDigitalSign] = useModalData();
    const settingsStore = useSelector(state => state.settings);
    const [entries] = useEntries(medicalRecordNumber);

    if (isEntryLoading || isAuditLoading)
        return <Spinner />;

    if (!entry)
        return "";

    const handleExportAction = () => {
        if (settingsStore.settings.digitalSignEnabled)
            toggleDigitalSign();
        else
            handleExportToPDF()
    }

    const handleExportToPDF = async (pin) => {
        setIsLoading(true);

        if (settingsStore.settings.digitalSignEnabled)
            toggleDigitalSign();

        try {
            var response = await exportToPDF(entry, pin?.password);

            const blob = response.data;
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            let fileName = `${patient.fullName}-${entry.group}-${entry.created}.pdf`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

            setIsLoading(false);
            Success("medicalRecordsNotifications.entry_Exported");
        }
        catch (error) {
            setIsLoading(false);
            HandleApiError(error);
            console.log(error);
        };
    }

    console.log(entry)
    return (
        <div>
            <Row>
                <Col md={12}>
                    <PatientInfo2 patient={patient} isLoading={isPatientLoading} />
                </Col>
                <Col md={12}>
                    <EntryNav medicalRecordNumber={medicalRecordNumber} visits={entries} />
                </Col>
            </Row>
            <Row>
                <Col md={2} lg={2}>
                    <Actions
                        onCreateAppointment={() => props.history.push(`/admin/agenda?medicalRecordNumber=${patient?.medicalRecordNumber}&source=${props.location.pathname}`)}
                        onRedirectTo={() => props.history.push(`/admin/medicalRecords/${medicalRecordNumber}`)}
                        onEditEntry={() => { props.history.push(`/admin/medicalRecords/${medicalRecordNumber}/newentry/${entryId}`) }}
                        onExportToPDF={handleExportAction}
                        isLoading={isLoading}
                        isProtocol={entry.protocolId}
                    />
                </Col>
                <Col md={5} lg={5}>
                    {
                        entry.protocolId &&
                        <Alert bsStyle="warning">{t("medicalRecords.textToProtocolarVisit")}&nbsp;
                            <NavLink to={`/admin/protocols/${entry.protocolId}/${getProtocolUrl(entry.typeId)}/${entry.visitId}/patient/${entry.numberInProtocol}`} className="nav-link" activeClassName="active">
                                {t("medicalRecords.linkToProtocolarVisit")}
                            </NavLink>
                        </Alert>
                    }
                    {/*<SummaryContainer
                        medicalRecordNumber={medicalRecordNumber}
                        entryId={entryId}
                    />*/}
                    <ObservationsContainer
                        medicalRecordNumber={medicalRecordNumber}
                        entryId={entryId}
                    />
                    <EntryView
                        audit={getLastChange("Text")}
                        entry={entry}
                    />
                    <DiagnosesContainer
                        medicalRecordNumber={medicalRecordNumber}
                        entryId={entryId}
                    />
                    <OrdersListContainer medicalRecordNumber={medicalRecordNumber} entryId={entryId} expandedAll={true} readOnly={true} />
                    <PrescriptionsListContainer medicalRecordNumber={medicalRecordNumber} entryId={entryId} expandedAll={true} readOnly={true} />
                    {
                        !settingsStore.settings.digitalSignEnabled &&
                        <SignWithOutInfo signedBy={entry.practitionerId} created={entry.created} />
                    }
                    {
                        settingsStore.settings.digitalSignEnabled &&
                        <DigitalSign signedBy={entry.signedBy} signStatus={entry.signStatus} signedDate={entry.signedDate} />
                    }
                </Col>
                <Col md={5} lg={5}>
                    <EntryItemsList
                        protocol={entry.protocolId}
                        entryStatus={entry.status}
                        entryId={entryId}
                        mrn={medicalRecordNumber}
                    //actual={false}
                    />
                </Col>
            </Row>
        </div>
    );
}