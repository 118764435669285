import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WithAuth } from '../../../components/Authorization/WithAuthProtocol.jsx';
import Card from '../../../components/Cards/Card.jsx';
import { Add, RemoveCircle } from '../../../components/Icons/Icons.jsx';
import { MonitorNoteForm, MonitorNotes } from '../../../components/Protocols/MonitorNotes';
import ProtocolContext from '../ProtocolContext/ProtocolContext.js';
import { useMonitorNotes } from './useMonitorNotes.js';

const MonitorNotesContainer = (props) => {
    const { t } = useTranslation();
    const { protocolId, documentId, visitId, patientId, type } = props;
    const context = useContext(ProtocolContext);
    const [isLoading, setIsLoading] = useState(true);
    const [, getNotes, , createNote, updateNote, deleteNote, changeStatusNote] = useMonitorNotes();
    const [notes, setNotes] = useState([]);
    const [note, setNote] = useState(null);
    const [collapse, setCollapse] = useState(true);

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = async () => {
            setIsLoading(true);
            try {
                const resp = await getNotes(protocolId, documentId);
                if (isSubscribed) {
                    setNotes(resp.data);
                    if (resp.data.length > 0)
                        setCollapse(false);
                    setIsLoading(false);
                }
            }
            catch (error) {
                if (isSubscribed) {
                    setNotes([]);
                    setIsLoading(false);
                }
            }
        }

        if (documentId && protocolId)
            fetchData();

        return () => isSubscribed = false;
    }, [protocolId, documentId]);

    const handleCreateNote = async (note) => {
        var createdNote = await createNote(type, documentId, protocolId, visitId, patientId, note);
        if (createdNote)
            setNotes(notes.concat(createdNote.data));
    }
    const handleEditNote = (noteId) => {
        var note = notes.filter(x => (x.id === noteId));
        if (note.length > 0)
            setNote(note[0]);
    }
    const handleNoteEdited = async (noteId, text) => {
        var updatedNote = await updateNote(protocolId, noteId, text);
        if (updatedNote) {
            let notesCopy = JSON.parse(JSON.stringify(notes));
            var index = notes.findIndex(x => x.id === noteId);
            notesCopy.splice(index, 1, updatedNote.data); //Reemplazo. 

            setNotes(notesCopy);
            setNote(null);
        }
    }
    const handleRemoveNote = async (noteId) => {
        try {
            await deleteNote(protocolId, noteId);
            setNotes(notes.filter(x => (x.id !== noteId)));
        }
        catch (error) {
            console.log(error)
        }
    }
    const handleStatusChange = async (noteId, status, response) => {
        try {
            var updatedNote = await changeStatusNote(protocolId, noteId, status, response);
            if (updatedNote) {
                setNotes(notes.filter(x => (x.id !== noteId))); // Elimino
            }
        }
        catch (error) {
            console.log(error)
        }
    }


    if (isLoading)
        return null;

    return (
        <Card
            title={
                <div>
                    <button className="btn-table-actions" style={{ verticalAlign: 'middle' }} type="button" onClick={() => setCollapse(!collapse)}>
                        {collapse ? <Add /> : <RemoveCircle />}
                    </button>
                    <span>{t("protocols.visitTracking.monitorNotes_Title")}</span>
                </div>
            }
            content={
                <>
                    {
                        !collapse &&
                        <>
                            {
                                !context.isDisabled &&
                                <WithAuth protocolId={protocolId} requiredPermission={["ExternalNotes.Add"]}>
                                    <MonitorNoteForm
                                        documentId={documentId}
                                        note={note}
                                        onCreate={handleCreateNote}
                                        onEdit={handleNoteEdited}
                                    />
                                </WithAuth>
                            }
                            <WithAuth protocolId={protocolId} requiredPermission={["ExternalNotes.List"]}>
                                <MonitorNotes
                                    notes={notes}
                                    documentId={documentId}
                                    onEdit={handleEditNote}
                                    onRemove={handleRemoveNote}
                                    onStatusChange={handleStatusChange}
                                />
                            </WithAuth>
                        </>
                    }
                </>
            }
        />
    );
}

export default MonitorNotesContainer;