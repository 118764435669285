import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Modal, Button, Grid, Row, Col, ControlLabel, FormGroup } from 'react-bootstrap'
import { CustomSelect } from '../CustomControls/CustomSelect'
import { TextBoxUsersMentions } from '../CustomControls/TextBoxUsersMentions'
import { getValidationAuditTrailModal } from '../Validations/FormValidationSchema'
import { FormErrorMessage } from '../Validations/FormValidationErrors'

export const AuditModal = (props) => {
    const { t } = useTranslation();

    return (
        <Modal show={true} onHide={props.onClose} bsSize="large">
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onClose}>{t('buttons.close')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

AuditModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
};

export const CreateAuditModal = (props) => {
    const { t } = useTranslation();

    const [reason, setReason] = useState(null);
    const [comments, setComments] = useState('');
    const handleChange = (name, value) => {
        if (name === "auditTrail.reason")
            setReason(value);
        if (name === "auditTrail.comments")
            setComments(value);

        getValidationAuditTrailModal()
            .isValid({ reason, comments })
            .then((valid) => {
                if (valid && errors)
                    setErrors(null);
            });
    }
    const handleBlur = () => {
        getValidationAuditTrailModal()
            .isValid({ reason, comments })
            .then((valid) => {
                if (valid && errors)
                    setErrors(null);
            });
    }

    const [errors, setErrors] = useState(null);
    const handleSubmit = () => {

        try {
            var isValid = getValidationAuditTrailModal().validateSync({ reason, comments });
            if (isValid) {
                props.onSave(reason, comments);
            }
        } catch (e) {
            setErrors(e.errors);
        }
    }

    return (
        <Modal show={true} onHide={props.onClose} bsSize="large">
            <Modal.Header closeButton>
                <Modal.Title>{t('auditTrail.modalTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomSelect
                    autoFocus
                    value={reason}
                    name="auditTrail.reason"
                    placeholder={t("commons.select")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={[
                        { id: 1, code: 'transcriptionError', title: t('auditTrail.reasons.transcriptionError') },
                        { id: 2, code: 'newDataObtained', title: t('auditTrail.reasons.newDataObtained') },
                        { id: 3, code: 'updatedData', title: t('auditTrail.reasons.updatedData') },
                        { id: 4, code: 'other', title: t('auditTrail.reasons.other') }
                    ]}
                />
                {
                    reason && reason.code === "other" &&
                    <>
                        <br></br>
                        <TextBoxUsersMentions
                            name="auditTrail.comments"
                            placeholder=""
                            value={comments}
                            onChange={handleChange} />
                    </>
                }
                {
                    errors &&
                    errors.map((error) => {
                        return <FormErrorMessage key={error.key}>{t(error.key)}</FormErrorMessage>
                    })
                }
            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle="info" onClick={props.onClose}>{t('buttons.cancel')}</Button>
                <Button bsStyle="success" onClick={handleSubmit}>{t('buttons.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

CreateAuditModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    title: PropTypes.string,
};

export const CreateAudit = (props) => {
    const { t } = useTranslation();

    const [reason, setReason] = useState(null);
    const [comments, setComments] = useState('');
    const handleChange = (name, value) => {
        if (name === "auditTrail.reason")
            setReason(value);
        if (name === "auditTrail.comments")
            setComments(value);

        getValidationAuditTrailModal()
            .isValid({ reason, comments })
            .then((valid) => {
                if (valid && errors)
                    setErrors(null);
            });
    }
    const handleBlur = () => {
        getValidationAuditTrailModal()
            .isValid({ reason, comments })
            .then((valid) => {
                if (valid && errors)
                    setErrors(null);
            });
    }

    const [errors, setErrors] = useState(null);
    const handleSubmit = () => {

        try {
            var isValid = getValidationAuditTrailModal().validateSync({ reason, comments });
            if (isValid) {
                props.onSave(reason, comments);
            }
        } catch (e) {
            setErrors(e.errors);
        }
    }

    return (
        <>
            <FormGroup>
                <Grid fluid>
                    <Row>
                        <Col sm={12}>
                            <ControlLabel>{t('auditTrail.modalTitle')}</ControlLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <CustomSelect
                                value={reason}
                                name="auditTrail.reason"
                                placeholder={t("commons.select")}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                options={[
                                    { id: 1, code: 'transcriptionError', title: t('auditTrail.reasons.transcriptionError') },
                                    { id: 2, code: 'newDataObtained', title: t('auditTrail.reasons.newDataObtained') },
                                    { id: 3, code: 'updatedData', title: t('auditTrail.reasons.updatedData') },
                                    { id: 4, code: 'other', title: t('auditTrail.reasons.other') }
                                ]}
                            />
                        </Col>
                        <Col sm={12}>
                            {
                                reason && reason.code === "other" &&
                                <>
                                    <br></br>
                                    <TextBoxUsersMentions
                                        name="auditTrail.comments"
                                        placeholder=""
                                        value={comments}
                                        onChange={handleChange} />
                                </>
                            }
                        </Col>
                        <Col sm={12}>
                            {
                                errors &&
                                errors.map((error) => {
                                    return <FormErrorMessage key={error.key}>{t(error.key)}</FormErrorMessage>
                                })
                            }
                        </Col>
                    </Row>
                </Grid>
            </FormGroup>

            <Button bsStyle="info" onClick={props.onClose}>{t('buttons.cancel')}</Button>
            <Button bsStyle="success" className="btn-right" onClick={handleSubmit}>{t('buttons.save')}</Button>
            <div className="clearfix"></div>
        </>
    );
}

CreateAudit.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    title: PropTypes.string,
};